import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { InvoiceGenerationConfirmationDialogComponent } from '../invoice-generation-confirmation-dialog/invoice-generation-confirmation-dialog';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-generate-invoice-dialog',
  templateUrl: './generate-invoice-dialog.component.html',
})
export class GenerateInvoiceDialogComponent implements OnInit {
  projectId: string = '';
  status: string = '';
  milestonesForm: FormGroup;
  milestonesEnabled: boolean[] = [];
  loading: boolean = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenerateInvoiceDialogComponent>,
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.milestonesForm = this.formBuilder.group({
      milestones: this.formBuilder.array([]),
    });
  }

  ngOnInit(): void {
    this.projectId = this.data.element.projectId;
    this.fetchMilestones();
  }

  fetchMilestones(): void {
    this.loading = true;
    this.projectService.getProjectById(this.projectId).subscribe({
      next: (res: any) => {
        const milestonesArray = res.data.mileStones.map((milestone: any) =>
          this.createMilestoneFormGroup(milestone)
        );
        this.milestonesForm.setControl(
          'milestones',
          this.formBuilder.array(milestonesArray)
        );
        this.milestonesEnabled = milestonesArray.map(() => false);
        this.loading = false;
        if (this.milestones.length > 0) {
          const firstMilestone = this.milestones.at(0);
          const invoiceStatus =
            firstMilestone.get('invoice')?.value?.invoiceStatus[0]
              ?.invoiceStatus || this.data.element.status;
          this.status = this.capitalizeStatus(invoiceStatus);
        }
      },
      error: (error) => {
        console.error('Error fetching milestones', error);
        this.loading = false;
      },
    });
  }

  capitalizeStatus(status: string): string {
    if (!status) return '';
    return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }

  enableMilestoneForm(index: number): void {
    const milestone = this.milestones.at(index);
    milestone.get('price')?.enable();
    milestone.get('dueDate')?.enable();
    this.milestonesEnabled[index] = true;
  }

  createMilestoneFormGroup(milestone: any = {}): FormGroup {
    const formGroup = this.formBuilder.group({
      mileStoneId: [milestone.mileStoneId || ''],
      mileStoneName: [
        { value: milestone.mileStoneName || '', disabled: true },
        Validators.required,
      ],
      price: [
        milestone.invoice?.invoiceItems[0].rate || milestone.price,
        Validators.required,
      ],
      dueDate: [new Date(milestone.dueDate) || '', Validators.required],
      paid: [milestone.paid || false],
      isInvoiceGenerated: [milestone.isInvoiceGenerated || false],
      invoice: [milestone.invoice || null],
    });

    if (milestone.invoice !== null) {
      formGroup.disable();
    }

    return formGroup;
  }

  get milestones(): FormArray {
    return this.milestonesForm.get('milestones') as FormArray;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onActionClick(index: number, action: string): void {
    if (action === 'generate') {
      this.addMilestone(index);
    } else if (action === 'modify') {
      this.updateMilestone(index);
    }
  }

  addMilestone(index: number): void {
    const milestone = this.milestones.at(index);

    const invoiceItem = {
      rate: milestone.get('price')?.value || '0',
      allocatedTime: '1',
    };

    const invoiceData: any = {
      invoiceDate: new Date().toISOString(),
      projectId: this.projectId,
      invoiceItems: [invoiceItem],
    };

    const mileStoneId = milestone.get('mileStoneId')?.value;
    if (mileStoneId) {
      invoiceData.mileStoneId = mileStoneId;
    }

    this.loading = true;

    this.projectService.addMilestone(invoiceData).subscribe(
      (response) => {
        this.dialog.open(InvoiceGenerationConfirmationDialogComponent, {
          height: 'auto',
          width: '467px',
        });
        this.fetchMilestones();
      },
      (error) => {
        console.error('Error generating invoice', error);
        this.loading = false;
      }
    );
  }

  updateMilestone(index: number): void {
    const milestone = this.milestones.at(index);

    const invoiceData = {
      invoiceDate: new Date().toISOString(),
      projectId: this.projectId,
      invoiceID: milestone.get('invoice')?.value.invoiceID || '',
      invoiceItems: [
        {
          rate: milestone.get('price')?.value || '0',
          allocatedTime: '1',
          invoiceItemID:
            milestone.get('invoice')?.value?.invoiceItems[0]?.invoiceItemID ||
            '',
        },
      ],
    };

    this.loading = true;

    this.projectService.updateMilestone(invoiceData).subscribe(
      (response) => {
        this.dialog.open(InvoiceGenerationConfirmationDialogComponent, {
          height: 'auto',
          width: '467px',
        });
        console.log('Milestone updated successfully', response);
        this.fetchMilestones();
      },
      (error) => {
        console.error('Error updating milestone', error);
        this.loading = false;
      }
    );
  }
}
