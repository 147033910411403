import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  @Output() confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() canceled = new EventEmitter<void>();

  onYesClick(): void {
    this.confirmed.emit(true);
  }

  onNoClick(): void {
    this.canceled.emit();
  }
}
