import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoicingService } from '../../../invoicing/services/invoicing.service';
import { takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { PageEvent } from '@angular/material/paginator';
import moment from 'moment';

@Component({
  selector: 'app-generate-invoice-side-panel',
  templateUrl: './generate-invoice-side-panel.component.html',
})
export class GenerateInvoiceSidePannelComponent {
  isOpen: boolean = false;
  showInvoiceDetails = false;
  loading: boolean = false;
  invoicingList: any[] = [];
  invoicingDetail: any[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  totalRecords: number = 0;
  lastInvoiceDate: string = '';
  modifyTriggered: boolean = false;
  generateTriggered: boolean = false;
  selectedInvoiceItemID: string = '';
  selectedInvoiceID: string = '';
  selectedYear: string = '2024';
  projectResoourceCheck: string = '';
  availableYears: string[] = [];
  type: string = '';
  projectId: string = '';

  constructor(
    private invoicingService: InvoicingService,
    private globalService: GlobalService
  ) {}

  @Input() accountId: string = '';
  @Input() initialLoad: boolean = true;
  @Input() billingCycle: string = '';
  @Input() creditTerm: string = '';
  @Input() element: any;

  @Output() modifyClick = new EventEmitter<void>();
  @Output() generateClick = new EventEmitter<void>();

  ngOnInit(): void {
    this.fetchAvailableYears();

    this.globalService.getProjectId().subscribe((projectId) => {
      this.projectId = projectId;
    });

    this.globalService.getProjectType().subscribe((projectType) => {
      this.type = projectType;
    });
  }

  fetchAvailableYears(): void {
    this.invoicingService
      .getInvoiceYear()
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.availableYears =
            data?.data?.records?.map((item: any) => item.year.toString()) || [];
          if (this.availableYears.length > 0) {
            this.selectedYear = this.availableYears[0];
          }
        },
        error: (error: any) => {
          console.error('Error fetching available years:', error);
        },
      });
  }

  onDrawerOpenedChange(isOpen: boolean): void {
    this.isOpen = isOpen;
    if (!isOpen) {
      this.resetFilters();
    }
  }

  onYearChange(selectedYear: string): void {
    this.selectedYear = selectedYear;
    this.getInvoicingList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
  }

  resetFilters(): void {
    this.selectedYear = '2024';
    this.invoicingList = [];
    this.pageNumber = 0;
    this.getInvoicingList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
  }

  toggleInvoiceDetails(isBack: boolean, invoiceID?: string): void {
    this.showInvoiceDetails = !this.showInvoiceDetails;
    if (invoiceID) {
      this.selectedInvoiceID = invoiceID;
      this.getInvoicingDetail(invoiceID);
    } else {
      this.getInvoicingList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      );
    }
  }

  formatInvoiceNumberDate(invoice: any): string {
    const startDate = new Date(this.billingCycle);
    const billingCycleDay = startDate.getDate();
    const createdAt = new Date(invoice.created_at);
    const month = createdAt.toLocaleString('default', { month: 'short' });
    const year = createdAt.getFullYear();

    const endDate = moment(startDate).add(1, 'month').format('DD MMM YYYY');
    return `${billingCycleDay} ${month} ${year} - ${endDate}`;
  }

  toggleGenerateInvoiceDetails(isBack: boolean, invoiceID?: string): void {
    this.generateTriggered = true;
    this.modifyTriggered = false;

    this.showInvoiceDetails = !this.showInvoiceDetails;
    if (invoiceID) {
      this.selectedInvoiceID = invoiceID;
      this.getInvoicingDetail(invoiceID);
    } else {
      this.getInvoicingList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      );
    }
  }

  toggleModifyInvoiceDetails(isBack: boolean, invoiceID?: string): void {
    this.modifyTriggered = true;
    this.generateTriggered = false;

    this.showInvoiceDetails = !this.showInvoiceDetails;
    if (invoiceID) {
      this.selectedInvoiceID = invoiceID;
      this.getInvoicingDetail(invoiceID);
    } else {
      this.getInvoicingList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy
      );
    }
  }

  getInvoicingList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.loading = true;

    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.selectedYear,
        '',
        this.projectId,
        '',
        '',
        this.type
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records || [];
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.invoicingList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getInvoicingDetail(invoiceId: string): void {
    this.loading = true;
    this.invoicingService
      .getInvoiceItemListById(invoiceId)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingDetail = data?.data || [];
          this.selectedInvoiceItemID =
            data?.data?.invoiceItems?.[0]?.invoiceItemID;
          this.projectResoourceCheck =
            data?.data?.invoiceItems?.[0]?.projectResource;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing Detail list:', error);
          this.loading = false;
        },
      });
  }

  getFormattedStatus(status: string): string {
    if (status === 'draft') {
      return 'Draft';
    } else if (status === 'sent') {
      return 'Sent';
    } else if (status === 'void') {
      return 'Void';
    } else if (status === 'received') {
      return 'Received';
    } else {
      return status;
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getInvoicingList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy || 'ASC'
    );
  }
}
