<div class="modal-wrapper p-6">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">Confirmation</h2>
    </div>
    <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
  </div>
  <div class="content-wrapper mt-2 mb-6">
    <p class="text textS">{{ data.message }}</p>
  </div>
  <div class="modal-footer d-flex justify-end">
    <sft-button
      [label]="'No, Cancel'"
      (click)="onNoClick()"
      className="mr-2"
      [color]="'primary'"
      [variant]="'stroked'"
      [disable]="false"
      [icon]="'home'"
      [disableRipple]="false"
      [size]="'large'"
    ></sft-button>
    <sft-button
      [label]="data.isDeleteAction ? 'Yes, Delete' : 'Yes, Remove'"
      (click)="onYesClick()"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
    ></sft-button>
  </div>
</div>
