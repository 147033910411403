import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { InvoiceGenerationConfirmationDialogComponent } from '../invoice-generation-confirmation-dialog/invoice-generation-confirmation-dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';

@Component({
  selector: 'app-invoice-date-dialog',
  templateUrl: './invoice-date-fixed-dialog.component.html',
})
export class InvoiceDateFixedDialogComponent implements OnInit {
  invoiceForm!: FormGroup;
  billingCycle: string = '';
  creditTerm: string = '';
  lastInvoiceDate: string = '';
  loading: boolean = false;
  invoiceItemID: string = '';
  @Output() updateInvoiceDetails = new EventEmitter<void>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InvoiceDateFixedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProjectService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.invoiceForm = this.fb.group({
      invoiceDate: ['', Validators.required],
    });
    (this.billingCycle = this.data.billingCycle),
      (this.creditTerm = this.data.creditTerm);
    this.lastInvoiceDate = this.data.lastInvoiceDate;
    this.invoiceItemID = this.data.invoiceItemID;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  generateInvoice(): void {
    if (this.invoiceForm.invalid) {
      return;
    }

    this.loading = true;

    const invoiceItemsArray = [
      {
        rate: this.data.fixedCost,
        allocatedTime: '1',
      },
    ];

    const invoiceData = {
      invoiceDate: this.invoiceForm.get('invoiceDate')?.value,
      projectId: this.data.projectId,
      invoiceID: this.data.invoiceID,
      invoiceItems: invoiceItemsArray,
    };

    if (this.data.actionType === 'generate') {
      this.projectService.generateFixedProjectInvoice(invoiceData).subscribe({
        next: (response) => {},
        error: (error) => {
          console.error('Error generating invoice:', error);
          this.loading = false;
        },
        complete: () => {
          this.openSuccessfulGenerationDialog();
          this.loading = false;
        },
      });
    } else if (this.data.actionType === 'modify') {
      const updateInvoiceData = {
        invoiceDate: this.invoiceForm.get('invoiceDate')?.value,
        invoiceID: this.data.invoiceID,
        projectId: this.data.projectId,
        invoiceItems: [
          {
            invoiceItemID: this.invoiceItemID,
            rate: this.data.fixedCost,
            allocatedTime: '1',
          },
        ],
      };

      this.projectService
        .modifyFixedProjectInvoice(updateInvoiceData)
        .subscribe({
          next: (response) => {
            // Handle successful response
          },
          error: (error) => {
            console.error('Error modifying invoice:', error);
            this.loading = false;
          },
          complete: () => {
            this.openSuccessfulGenerationDialog();
            this.loading = false;
          },
        });
    }
  }

  openSuccessfulGenerationDialog(): void {
    this.dialogRef.close();

    const dialogRef = this.dialog.open(
      InvoiceGenerationConfirmationDialogComponent,
      {
        height: 'auto',
        width: '467px',
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      this.updateInvoiceDetails.emit();
    });
  }
}
