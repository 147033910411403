import { Component, signal, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { CostCenterService } from '../../../cost-center/services/cost-center.service';
import { ExpenseService } from 'src/app/admin-portal/modules/expense-detail/services/expense.service';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { PageEvent } from '@angular/material/paginator';
import { CoreTeamService } from 'src/app/admin-portal/modules/core-team/services/core-team.service';
import { environment } from 'src/environment/environment';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSort, Sort } from '@angular/material/sort';
import { ResourceSidePannelComponent } from 'src/app/admin-portal/modules/account/components/resource-side-panel/resource-side-panel.component';
import { ExpenseDialogComponent } from 'src/shared/components/dialog/admin-dialogs/expense-dialog/expense-dialog.component';
import { ProjectDialogAccountsDetailComponent } from 'src/shared/components/dialog/admin-dialogs/project-dialog-account-detail/project-dialog-account-detail.component';
import { CostCenterDialogComponent } from 'src/shared/components/dialog/admin-dialogs/cost-center-dialog-account-detail/cost-center-dialog-account-detail';
import { CoreTeamDailogComponent } from 'src/shared/components/dialog/admin-dialogs/core-team-dialog/core-team-dialog';
import { ConfirmationDialogComponent } from 'src/shared/components/dialog/admin-dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { OffBoardConfirmationDialogComponent } from 'src/shared/components/dialog/admin-dialogs/off-board-confirmation-dialog/off-board-confirmation-dialog.component';
import { GenerateInvoiceSidePannelComponent } from '../generate-invoice-side-panel/generate-invoice-side-panel.component';
import { GenerateInvoiceDialogComponent } from 'src/shared/components/dialog/admin-dialogs/generate-invoice-dialog/generate-invoice-dialog.component';
import { GenerateInvoiceFixedCostSidePannelComponent } from '../generate-invoice-fixed-cost-side-panel/generate-invoice-fixed-cost-side-panel.component';

export interface CostCenters {
  name: string;
  position: string;
  weight: string;
  action: string;
  symbol: string;
}
export interface CoreTeams {
  name: string;
  costCenter: string;
  weight: string;
  salary: number;
  status: string;
  action: string;
  date: string;
}

export interface Projects {
  name: string;
  action: string;
  symbol: string;
  date: string;
  resource: string;
  projectaction: string;
}
export interface Expenses {
  name: string;
  type: string;
  quantiy: string;
  amount: string;
  action: string;
}

interface ActivityLog {
  timestamp: string;
  user: {
    name: string;
  };
  activityDetail: string;
}

@Component({
  selector: 'app-accout-action-detail',
  templateUrl: './account-action-detail.component.html',
})
export class AccountActionDetailComponent {
  id!: string;
  accountName!: string;
  email!: string;
  financialName!: string;
  accountManager!: string;
  phone!: string;
  Cspoc!: string;
  currency!: string;
  startDate!: string;
  reviewDate!: string;
  lastUpdate!: string;
  fullAddress!: string;
  logo!: string;
  city!: string;
  state!: string;
  country!: string;
  zipCode!: string;
  timeZone!: string;
  costCenterPageNumber: number = 0;
  coreTeamPageNumber: number = 0;
  projectPageNumber: number = 0;
  expensePageNumber: number = 0;
  activityPageNumber: number = 0;
  costCenterPageSize: number = 25;
  coreTeamPageSize: number = 25;
  projectPageSize: number = 25;
  expensePageSize: number = 25;
  activityPageSize: number = 25;
  search: string = '';
  sortBy: any = 'createdAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  costCenterForm!: FormGroup;
  coreTeamForm!: FormGroup;
  projectForm!: FormGroup;
  expenseForm!: FormGroup;
  activityForm!: FormGroup;
  addressArray: any = [];
  contractsArray: any = [];
  financialsArray: any = [];
  expenseList: any[] = [];
  costList: any[] = [];
  projectList: any[] = [];
  resourceList: any[] = [];
  coreTeamList: any[] = [];
  totalCostRecords: number = 0;
  totalCoreTeamRecords: number = 0;
  totalProjectRecords: number = 0;
  totalExpenseRecords: number = 0;
  totalActivityRecords: number = 0;
  element: any;
  expenseId: string = '';
  cost_centerID: string = '';
  loading: boolean = false;
  baseUrl = environment.apiUrl;
  selectedTabIndex: number = 0;
  selectTab: string = 'Cost Center Management';
  resourceId: string = '';
  costCenterId: string = '';
  lastSortBy: string = '';
  billingCycle: string = '';
  creditTerm: string = '';
  sortByCostCenter: 'code' | 'modifiedAt' = 'modifiedAt';
  sortByCoreTeam: 'resourceName' | 'modifiedAt' = 'modifiedAt';
  sortByProject: 'name' | 'modifiedAt' = 'modifiedAt';
  sortByExpense: 'name' | 'modifiedAt' = 'modifiedAt';
  selectedStatus: string[] = [];
  activityLogsGrouped: { date: string; logs: ActivityLog[] }[] = [];

  readonly panelOpenState = signal(false);

  setElement(selectedElement: any): void {
    this.element = selectedElement;
  }

  constructor(
    public dialog: MatDialog,
    private accountService: AccountService,
    private globalService: GlobalService,
    private route: ActivatedRoute,
    private formbuilder: FormBuilder,
    private costService: CostCenterService,
    private expenseService: ExpenseService,
    private projectService: ProjectService,
    private coreTeamService: CoreTeamService,
    private router: Router
  ) {}

  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild('resource') resourceComponenet!: ResourceSidePannelComponent;
  @ViewChild('invoice')
  generateInvoiceComponenet!: GenerateInvoiceSidePannelComponent;
  @ViewChild('fixedInvoice')
  generateInvoiceFixedCostSidePannelComponent!: GenerateInvoiceFixedCostSidePannelComponent;

  ngOnInit(): void {
    this.sortByCostCenter = 'code';
    this.sortByCoreTeam = 'resourceName';
    this.sortByProject = 'name';
    this.sortByExpense = 'name';
    this.id = this.route.snapshot.params['id'];
    this.sortBy = 'createdAt';
    this.orderBy = 'DESC';

    this.getCostListByAccountId(
      this.costCenterPageNumber,
      this.costCenterPageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );

    this.getCoreTeamByAccountId(
      this.coreTeamPageNumber,
      this.coreTeamPageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );

    this.getProjectListByAccountId(
      this.projectPageNumber,
      this.projectPageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );

    this.getExpenseListByAccountId(
      this.expensePageNumber,
      this.expensePageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );

    this.fetchAccountDetails();

    this.expenseForm = this.formbuilder.group({
      searchQuery: [''],
    });
    this.costCenterForm = this.formbuilder.group({
      searchQuery: [''],
    });
    this.projectForm = this.formbuilder.group({
      searchQuery: [''],
    });
    this.coreTeamForm = this.formbuilder.group({
      searchQuery: [''],
      status: [''],
    });
    this.activityForm = this.formbuilder.group({
      searchQuery: [''],
    });

    this.searchCostList();
    this.searchCoreTeam();
    this.searchProjectList();
    this.searchExpenseList();
    this.searchActivityLogs();

    this.filterByStatus();

    this.selectedTabIndex = 0;
    this.onTabChange({ index: 0 } as MatTabChangeEvent);
  }

  displayedColumns: string[] = [
    'position',
    'name',
    'weight',
    'symbol',
    'action',
  ];
  dataSource = new MatTableDataSource<CostCenters>([]);

  displayedColumns1: string[] = [
    'name',
    'costCenter',
    'weight',
    'salary',
    'status',
    'date',
    'action',
  ];
  dataSource1 = new MatTableDataSource<CoreTeams>([]);

  displayedColumns2: string[] = [
    'name',
    'symbol',
    'date',
    'action',
    'resource',
    'projectaction',
  ];
  dataSource2 = new MatTableDataSource<Projects>([]);

  displayedColumns3: string[] = [
    'name',
    'type',
    'quantity',
    'amount',
    'action',
  ];
  dataSource3 = new MatTableDataSource<Expenses>([]);

  fetchAccountDetails(): void {
    this.accountService.getAccountById(this.id).subscribe((res) => {
      const accountData = res.data;
      this.accountName = accountData?.name;
      this.lastUpdate = accountData?.modifiedAt;
      this.logo = accountData?.logo
        ? `${this.baseUrl}/files/${accountData.logo}`
        : this.getDefaultLogoText(accountData.logo);
      this.contractsArray = Object.values(accountData?.contracts);
      this.contractsArray.forEach((data: any) => {
        this.accountManager = data?.accountManager;
        this.Cspoc = data?.clientSpoc;
        this.startDate = data?.startDate;
        this.reviewDate = data?.reviewDate;
      });
      this.addressArray = Object.values(accountData?.address);
      this.addressArray.forEach((data: any) => {
        this.fullAddress = data?.fullAddress;
        this.city = data?.city;
        this.state = data?.state;
        this.country = data?.country;
        this.zipCode = data?.zipCode;
        this.timeZone = data?.timeZone;
      });
      this.financialsArray = Object.values(accountData?.financials);
      this.financialsArray.forEach((data: any) => {
        this.financialName = data?.name;
        this.email = data?.email;
        this.phone = data?.phone;
        this.currency = data?.fin_currency;
        this.billingCycle = data?.billingCycle;
        this.creditTerm = data?.creditTerm;
        this.globalService.setCurrency(this.currency);
      });
    });
  }

  getDefaultLogoText(accountName: string): string {
    if (!accountName) return '';

    const firstChar = accountName.charAt(0);
    const indexOfFirstSpace = accountName.indexOf(' ');
    let secondChar = '';
    if (
      indexOfFirstSpace !== -1 &&
      indexOfFirstSpace < accountName.length - 1
    ) {
      secondChar = accountName.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    switch (this.selectedTabIndex) {
      case 0:
        this.selectTab = 'Cost Centre Management';
        break;
      case 1:
        this.selectTab = 'Core Team Management';
        break;
      case 2:
        this.selectTab = 'Project Management';
        break;
      case 3:
        this.selectTab = 'Re-Occurring Expense Details';
        break;
      case 4:
        this.selectTab = 'Activity log';
        this.fetchActivityLogs();
        break;
    }
  }

  onSortChangeCostCenter(event: Sort): void {
    const sortByCostCenter = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortByCostCenter !== this.lastSortBy) {
      this.coreTeamPageNumber = 0;
    }
    this.lastSortBy = sortByCostCenter;

    this.costService
      .getCostListByAccountId(
        this.costCenterPageNumber,
        this.costCenterPageSize,
        this.search,
        sortByCostCenter,
        validOrderBy,
        this.id
      )
      .subscribe({
        next: (data: any) => {
          this.costList = data?.data?.records || [];
          this.totalCostRecords = data?.data?.count || 0;
          this.dataSource.data = this.costList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Cost List request:', error);
        },
      });
  }

  onSortChangeCoreTeam(event: Sort): void {
    const sortByCoreTeam = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortByCoreTeam !== this.lastSortBy) {
      this.coreTeamPageNumber = 0;
    }
    this.lastSortBy = sortByCoreTeam;

    this.coreTeamService
      .getCoreTeamByAccountId(
        this.id,
        this.coreTeamPageNumber,
        this.coreTeamPageSize,
        this.search,
        sortByCoreTeam,
        validOrderBy
      )
      .subscribe({
        next: (data: any) => {
          this.coreTeamList = data?.data?.records || [];
          this.totalCoreTeamRecords = data?.data?.count || 0;
          this.dataSource.data = this.coreTeamList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Core Team List request:', error);
        },
      });
  }

  onSortChangeProject(event: Sort): void {
    const sortByProject = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortByProject !== this.lastSortBy) {
      this.projectPageNumber = 0;
    }
    this.lastSortBy = sortByProject;

    this.projectService
      .getProjectListByAccountId(
        this.projectPageNumber,
        this.projectPageSize,
        this.search,
        sortByProject,
        validOrderBy,
        this.id
      )
      .subscribe({
        next: (data: any) => {
          this.projectList = data?.data?.records || [];
          this.totalProjectRecords = data?.data?.count || 0;
          this.dataSource.data = this.projectList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Project List request:', error);
        },
      });
  }

  onSortChangeExpense(event: Sort): void {
    const sortByExpense = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortByExpense !== this.lastSortBy) {
      this.expensePageNumber = 0;
    }
    this.lastSortBy = sortByExpense;

    this.expenseService
      .getExpenseListByAccountId(
        this.expensePageNumber,
        this.expensePageSize,
        this.search,
        sortByExpense,
        validOrderBy,
        this.id
      )
      .subscribe({
        next: (data: any) => {
          this.expenseList = data?.data?.records || [];
          this.totalExpenseRecords = data?.data?.count || 0;
          this.dataSource.data = this.expenseList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Expense List request:', error);
        },
      });
  }

  onCostCenterPageChange(event: PageEvent): void {
    this.costCenterPageNumber = event.pageIndex;
    this.costCenterPageSize = event.pageSize;
    this.getCostListByAccountId(
      this.costCenterPageNumber,
      this.costCenterPageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );
  }

  onCoreTeamPageChange(event: PageEvent): void {
    this.coreTeamPageNumber = event.pageIndex;
    this.coreTeamPageSize = event.pageSize;
    this.getCoreTeamByAccountId(
      this.coreTeamPageNumber,
      this.coreTeamPageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );
  }

  onProjectPageChange(event: PageEvent): void {
    this.projectPageNumber = event.pageIndex;
    this.projectPageSize = event.pageSize;
    this.getProjectListByAccountId(
      this.projectPageNumber,
      this.projectPageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );
  }

  onExpensePageChange(event: PageEvent): void {
    this.expensePageNumber = event.pageIndex;
    this.expensePageSize = event.pageSize;
    this.getExpenseListByAccountId(
      this.expensePageNumber,
      this.expensePageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );
  }

  onActivityPageChange(event: PageEvent): void {
    this.activityPageNumber = event.pageIndex;
    this.activityPageSize = event.pageSize;
    this.fetchActivityLogs();
  }

  editAccount(): void {
    this.router.navigate(['/account/edit', this.id]);
  }

  openAddCostDialog(): void {
    const dialogRef = this.dialog.open(CostCenterDialogComponent, {
      height: 'auto',
      width: '467px',
      data: { accountID: this.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getCostListByAccountId(
          this.costCenterPageNumber,
          this.costCenterPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      }
    });
  }

  openEditCostDialog(element: any): void {
    const dialogRef = this.dialog.open(CostCenterDialogComponent, {
      data: { isEditMode: true, element: element, accountID: this.id },
      height: 'auto',
      width: '467px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        const index = this.costList.findIndex((p) => p.id === element.id);
        if (index !== -1) {
          this.costList.splice(index, 1);
          this.costList.unshift(result.updatedCostList);
        }
        this.getCostListByAccountId(
          this.costCenterPageNumber,
          this.coreTeamPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      }
    });
  }

  openAddCoreTeamDialog(): void {
    const dialogRef = this.dialog.open(CoreTeamDailogComponent, {
      height: 'auto',
      width: '467px',
      data: { accountID: this.id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getCoreTeamByAccountId(
          this.coreTeamPageNumber,
          this.coreTeamPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      }
    });
  }

  openEditCoreTeamDialog(element: any): void {
    const dialogRef = this.dialog.open(CoreTeamDailogComponent, {
      data: { isEditMode: true, element: element, accountID: this.id },
      height: 'auto',
      width: '467px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        const index = this.coreTeamList.findIndex((p) => p.id === element.id);
        if (index !== -1) {
          this.coreTeamList.splice(index, 1);
          this.coreTeamList.unshift(result.updatedCoreTeamList);
        }
        this.getCoreTeamByAccountId(
          this.coreTeamPageNumber,
          this.coreTeamPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      }
    });
  }

  openProjectDialog(): void {
    const dialogRef = this.dialog.open(ProjectDialogAccountsDetailComponent, {
      data: { accountID: this.id },
      height: 'auto',
      width: '467px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getProjectListByAccountId(
          this.projectPageNumber,
          this.projectPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      }
    });
  }

  openEditProjectDialog(element: any): void {
    const dialogRef = this.dialog.open(ProjectDialogAccountsDetailComponent, {
      data: { isEditMode: true, element: element, accountID: this.id },
      height: 'auto',
      width: '467px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        const index = this.projectList.findIndex((p) => p.id === element.id);
        if (index !== -1) {
          this.projectList.splice(index, 1);
          this.projectList.unshift(result.updatedProjectList);
        }
        this.getProjectListByAccountId(
          this.projectPageNumber,
          this.projectPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      }
    });
  }

  openResourceDrawer(projectId: string): void {
    this.globalService.setRProjectId(projectId);
    if (this.resourceComponenet) {
      this.resourceComponenet.isOpen = !this.resourceComponenet.isOpen;
    } else {
    }
  }

  openInvoiceDrawer(project: any, accountId: string, type: string): void {
    this.globalService.setProjectId(project.projectId);
    this.globalService.setAccountId(accountId);

    if (this.generateInvoiceComponenet) {
      this.generateInvoiceComponenet.isOpen = true;
      this.generateInvoiceComponenet.type = type;
    }
  }

  openFixedCostInvoiceDrawer(project: any, type: string): void {
    this.globalService.setProjectId(project.projectId);
    this.globalService.setFixedCost(project.fixed_cost);
    const fixedCostRate = project.fixedCostRate;
    if (this.generateInvoiceFixedCostSidePannelComponent) {
      this.generateInvoiceFixedCostSidePannelComponent.rate = fixedCostRate;
      this.generateInvoiceFixedCostSidePannelComponent.isOpen =
        !this.generateInvoiceFixedCostSidePannelComponent.isOpen;
    }
  }

  openGenerateInvoiceDialogForFixedCost(element: string, type: string): void {
    const dialogRef = this.dialog.open(GenerateInvoiceDialogComponent, {
      data: { element: element, type: type },
      height: 'auto',
      width: '572px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getProjectListByAccountId(
          this.projectPageNumber,
          this.projectPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      }
    });
  }

  handleProjectAction(element: any): void {
    if (element?.cost_modal === 'fixed') {
      this.globalService.setProjectType('project_fixed');
      this.projectService
        .getProjectById(element.projectId)
        .subscribe((res: any) => {
          if (res.data.isMileStone) {
            this.openGenerateInvoiceDialogForFixedCost(
              element,
              'project_milestone'
            );
          } else {
            this.openFixedCostInvoiceDrawer(element, 'project_fixed');
          }
        });
    } else if (element?.cost_modal === 'hourly') {
      this.globalService.setProjectType('project_hourly');
      this.openInvoiceDrawer(element, this.id, 'project_hourly');
    } else {
      this.openInvoiceDrawer(element, this.id, 'project_daily');
      this.globalService.setProjectType('project_daily');
    }

    this.triggerInvoiceListing();
  }

  triggerInvoiceListing(): void {
    if (this.generateInvoiceComponenet) {
      this.generateInvoiceComponenet.getInvoicingList(
        this.generateInvoiceComponenet.pageNumber,
        this.generateInvoiceComponenet.limit,
        this.generateInvoiceComponenet.search,
        this.generateInvoiceComponenet.sortBy,
        this.generateInvoiceComponenet.orderBy
      );
    }

    if (this.generateInvoiceFixedCostSidePannelComponent) {
      this.generateInvoiceFixedCostSidePannelComponent.getInvoicingList(
        this.generateInvoiceFixedCostSidePannelComponent.pageNumber,
        this.generateInvoiceFixedCostSidePannelComponent.limit,
        this.generateInvoiceFixedCostSidePannelComponent.search,
        this.generateInvoiceFixedCostSidePannelComponent.sortBy,
        this.generateInvoiceFixedCostSidePannelComponent.orderBy
      );
    }
  }

  refreshProjectDataCount(): void {
    this.getProjectListByAccountId(
      this.projectPageNumber,
      this.projectPageSize,
      this.search,
      this.sortBy,
      this.orderBy,
      this.id
    );
  }

  openExpenseDialog(): void {
    const dialogRef = this.dialog.open(ExpenseDialogComponent, {
      data: { accountID: this.id },
      height: 'auto',
      width: '467px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getExpenseListByAccountId(
          this.expensePageNumber,
          this.expensePageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      }
    });
  }

  openExpenseEditDialog(element: any): void {
    const dialogRef = this.dialog.open(ExpenseDialogComponent, {
      data: { isEditMode: true, element: element, accountID: this.id },
      height: 'auto',
      width: '467px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        const index = this.expenseList.findIndex((p) => p.id === element.id);
        if (index !== -1) {
          this.expenseList.splice(index, 1);
          this.expenseList.unshift(result.updatedExpenseList);
        }
        this.getExpenseListByAccountId(
          this.expensePageNumber,
          this.expensePageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      }
    });
  }

  deleteCost(costCenterId: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: '467px',
      data: {
        message: 'Are you sure you want to delete this cost center?',
        isDeleteAction: true,
      },
    });

    dialogRef.componentInstance.canceled.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.componentInstance.confirmed.subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.costService.deleteCost(costCenterId).subscribe({
          next: () => {
            this.getCostListByAccountId(
              this.costCenterPageNumber,
              this.costCenterPageSize,
              this.search,
              this.sortBy,
              this.orderBy,
              this.id
            );
            dialogRef.close();
          },
          error: (error: any) => {
            console.error('Error deleting cost center:', error);
          },
        });
      }
    });
  }

  offBoardCoreTeamMember(core_teamID: string, element: any): void {
    const offBoardDate = element?.ct_off_board_date
      ? new Date(element.ct_off_board_date)
      : null;
    const dialogRef = this.dialog.open(OffBoardConfirmationDialogComponent, {
      height: 'auto',
      width: '467px',
      data: {
        message: 'Select the off Boarding date',
        isDeleteAction: true,
        core_teamID: core_teamID,
        offBoardDate: offBoardDate,
        element: element,
      },
    });

    dialogRef.componentInstance.canceled.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.componentInstance.confirmed.subscribe(
      (result: { confirmed: boolean; date: Date | null }) => {
        if (result.confirmed) {
          const statusData = {
            core_teamID: core_teamID,
            status: 'off_board',
            offBoardDate: result.date ? result.date.toISOString() : '',
          };

          this.coreTeamService.updateCoreTeamStatus(statusData).subscribe({
            next: () => {
              this.getCoreTeamByAccountId(
                this.coreTeamPageNumber,
                this.coreTeamPageSize,
                this.search,
                this.sortBy,
                this.orderBy,
                this.id
              );
              dialogRef.close();
            },
            error: (error: any) => {
              console.error('Error updating core team status:', error);
            },
          });
        }
      }
    );
  }

  deleteProject(projectId: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: '467px',
      data: {
        message: 'Are you sure you want to delete this project?',
        isDeleteAction: true,
      },
    });

    dialogRef.componentInstance.canceled.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.componentInstance.confirmed.subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.projectService.deleteProject(projectId).subscribe({
          next: () => {
            this.getProjectListByAccountId(
              this.projectPageNumber,
              this.projectPageSize,
              this.search,
              this.sortBy,
              this.orderBy,
              this.id
            );
            dialogRef.close();
          },
          error: (error: any) => {
            console.error('Error deleting project:', error);
          },
        });
      }
    });
  }

  deleteExpense(projectId: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: '467px',
      data: {
        message: 'Are you sure you want to delete this expense?',
        isDeleteAction: true,
      },
    });

    dialogRef.componentInstance.canceled.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.componentInstance.confirmed.subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.expenseService.deleteExpense(projectId).subscribe({
          next: () => {
            this.getExpenseListByAccountId(
              this.expensePageNumber,
              this.expensePageSize,
              this.search,
              this.sortBy,
              this.orderBy,
              this.id
            );
            dialogRef.close();
          },
          error: (error: any) => {
            console.error('Error deleting expense:', error);
          },
        });
      }
    });
  }

  searchCostList(): void {
    this.costCenterForm
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getCostListByAccountId(
          this.costCenterPageNumber,
          this.costCenterPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      });
  }

  searchCoreTeam(): void {
    this.coreTeamForm
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getCoreTeamByAccountId(
          this.coreTeamPageNumber,
          this.coreTeamPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      });
  }

  searchProjectList(): void {
    this.projectForm
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getProjectListByAccountId(
          this.projectPageNumber,
          this.projectPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      });
  }

  searchExpenseList(): void {
    this.expenseForm
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getExpenseListByAccountId(
          this.expensePageNumber,
          this.expensePageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id
        );
      });
  }

  getCostListByAccountId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    accountId: string
  ): void {
    this.loading = true;
    this.costService
      .getCostListByAccountId(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        accountId
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response: any) => {
          this.costList = response?.data?.records || [];
          this.totalCostRecords = response?.data?.count || 0;
          this.loading = false;
          this.costList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching cost list:', error);
        },
      });
  }

  getCoreTeamByAccountId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    accountId: string,
    status?: string
  ): void {
    this.loading = true;
    this.coreTeamService
      .getCoreTeamByAccountId(
        accountId,
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        status
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response: any) => {
          this.coreTeamList = response?.data?.records || [];
          this.totalCoreTeamRecords = response?.data?.count || 0;
          this.loading = false;
          this.coreTeamList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching core team list:', error);
        },
      });
  }

  filterByStatus(): void {
    this.coreTeamForm
      .get('status')
      ?.valueChanges.pipe(
        debounceTime(300),
        takeUntil(this.globalService.componentDestroyed(this))
      )
      .subscribe((status) => {
        this.getCoreTeamByAccountId(
          this.coreTeamPageNumber,
          this.coreTeamPageSize,
          this.search,
          this.sortBy,
          this.orderBy,
          this.id,
          status
        );
      });
  }

  getProjectListByAccountId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    accountId: string
  ): void {
    this.loading = true;
    this.projectService
      .getProjectListByAccountId(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        accountId
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response: any) => {
          this.projectList = response?.data?.records || [];
          this.projectList.sort((a, b) => {
            return (
              new Date(b.modified_at).getTime() -
              new Date(a.modified_at).getTime()
            );
          });
          this.totalProjectRecords = response?.data?.count || 0;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in fetching project list:', error);
        },
      });
  }

  getExpenseListByAccountId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    accountId: string
  ): void {
    this.loading = true;
    this.expenseService
      .getExpenseListByAccountId(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        accountId
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (response: any) => {
          this.expenseList = response?.data?.records || [];
          this.totalExpenseRecords = response?.data?.count || 0;
          this.loading = false;
          this.expenseList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching expense list:', error);
        },
      });
  }

  isStatusOngoing(status: string): boolean {
    return (
      !!status &&
      (status.toLowerCase() === 'active' || status.toLowerCase() === 'ongoing')
    );
  }

  isStatusOnHold(status: string): boolean {
    return !!status && status.toLowerCase() === 'hold';
  }

  isStatusCompleted(status: string): boolean {
    return !!status && status.toLowerCase() === 'completed';
  }

  getFormattedStatus(status: string): string {
    switch (status.toLowerCase()) {
      case 'active':
        return 'Ongoing';
      case 'hold':
        return 'On hold';
      case 'completed':
        return 'Completed';
      default:
        return '';
    }
  }

  isCoreTeamStatusOffBoarded(status: string): boolean {
    return !!status && status.toLowerCase() === 'off_board';
  }

  isCoreTeamStatusActive(status: string): boolean {
    return !!status && status.toLowerCase() === 'active';
  }

  getCoreTeamFormattedStatus(status: string): string {
    switch (status.toLowerCase()) {
      case 'active':
        return 'Active';
      case 'off_board':
        return 'Off-Boarded';
      default:
        return '';
    }
  }

  isValidNumber(value: any): boolean {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  fetchActivityLogs(): void {
    this.loading = true;
    this.accountService
      .getAllActivityLogs(
        this.activityPageNumber,
        this.activityPageSize,
        this.search,
        'timestamp',
        'DESC'
      )
      .subscribe((res) => {
        const logs: ActivityLog[] = res.data.records;
        this.totalActivityRecords = res?.data?.count || 0;
        const groupedLogs: { [date: string]: ActivityLog[] } = {};
        logs.forEach((log: ActivityLog) => {
          const date = new Date(log.timestamp).toDateString();
          if (!groupedLogs[date]) {
            groupedLogs[date] = [];
          }
          groupedLogs[date].push(log);
        });
        this.activityLogsGrouped = Object.entries(groupedLogs).map(
          ([date, logs]) => ({
            date,
            logs,
          })
        );
        this.loading = false;
      });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (this.isToday(date)) {
      return `Todays - ${this.formatDateToString(date)}`;
    } else if (this.isYesterday(date)) {
      return `Yesterday - ${this.formatDateToString(date)}`;
    } else {
      return this.formatDateToString(date);
    }
  }

  isToday(date: Date): boolean {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }

  isYesterday(date: Date): boolean {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return (
      date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear()
    );
  }

  private formatDateToString(date: Date): string {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return date.toLocaleDateString('en-US', options);
  }

  getDefaultPhotoText(name: string): string {
    if (!name) return '';

    const names = name.trim().split(/\s+/);
    let firstChar = '';
    let secondChar = '';

    if (names.length > 0) {
      firstChar = names[0].charAt(0);
      if (names.length > 1) {
        secondChar = names[1].charAt(0);
      }
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  searchActivityLogs(): void {
    this.loading = true;
    this.activityForm
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.fetchActivityLogs();
      });
  }

  goBack() {
    this.router.navigateByUrl('/account');
  }
}
