import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { InvoiceGenerationConfirmationDialogComponent } from '../invoice-generation-confirmation-dialog/invoice-generation-confirmation-dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';

@Component({
  selector: 'app-invoice-date-dialog',
  templateUrl: './invoice-date-dialog.component.html',
})
export class InvoiceDateDialogComponent implements OnInit {
  invoiceForm!: FormGroup;
  billingCycle: string = '';
  creditTerm: string = '';
  lastInvoiceDate: string = '';
  loading: boolean = false;
  errorMessage: string = '';

  @Output() updateInvoiceDetails = new EventEmitter<void>();

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InvoiceDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private projectService: ProjectService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.invoiceForm = this.fb.group({
      invoiceDate: ['', Validators.required],
    });
    this.billingCycle = this.data.billingCycle;
    this.creditTerm = this.data.creditTerm;
    this.lastInvoiceDate = this.data.lastInvoiceDate;    
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  generateInvoice(): void {
    if (this.invoiceForm.invalid) {
      return;
    }
  
    this.loading = true;
    this.errorMessage = '';
  
    const invoiceItems = this.data.resources.map((resource: any) => ({
      description: resource.role,
      rate: resource.rate,
      allocatedTime: resource.allocatedTime,
      projectResourceID: resource.projectResourceID,
    }));
  
    const invoiceData = {
      invoiceDate: this.invoiceForm.get('invoiceDate')?.value,
      projectId: this.data.projectId,
      accountID: this.data.accountID,
      invoiceID: this.data.invoiceId,
      invoiceItems: invoiceItems,
    };
  
    if (this.data.actionType === 'generate') {
      this.projectService
        .generateDailyAndHourlyProjectInvoice(invoiceData)
        .subscribe({
          next: (response: any) => {
            this.loading = false;
            this.openSuccessfulGenerationDialog();
          },
          error: (error: any) => {
            this.loading = false;
            this.errorMessage =
              error.error?.message || 'An unexpected error occurred.';
            console.error('Error generating invoice:', error);
          },
        });
    } else if (this.data.actionType === 'modify') {
      const updateInvoiceItems = this.data.resources.map((resource: any) => ({
        rate: resource.rate,
        allocatedTime: resource.allocatedTime,
        invoiceItemID: resource.invoiceItemID,
      }));
  
      const updateInvoiceData = {
        invoiceDate: this.invoiceForm.get('invoiceDate')?.value,
        invoiceID: this.data.invoiceId,
        invoiceItems: updateInvoiceItems,
      };
  
      this.projectService
        .modifyDailyAndHourlyProjectInvoice(updateInvoiceData)
        .subscribe({
          next: (response: any) => {
            this.loading = false;
            this.openSuccessfulGenerationDialog();
          },
          error: (error: any) => {
            this.loading = false;
  
            if (error.error?.message) {
              this.errorMessage = error.error.message;
            } else if (error.status === 409) {
              this.errorMessage = 'Something Went Wrong!';
            } else {
              this.errorMessage = error.message || this.errorMessage;
            }
  
            console.error('Error modifying invoice:', error);
          },
        });
    }
  }  

  openSuccessfulGenerationDialog(): void {
    this.dialogRef.close();

    const dialogRef = this.dialog.open(
      InvoiceGenerationConfirmationDialogComponent,
      {
        height: 'auto',
        width: '467px',
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      this.updateInvoiceDetails.emit();
    });
  }
}
