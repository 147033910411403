import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ProjectDialogComponent } from './admin-dialogs/project-dialog/project-dialog';
import { StorybookGlobalModule } from 'src/stories/components/storybook-global.module';
import { CostCenterDialogComponent } from './admin-dialogs/cost-center-dialog-account-detail/cost-center-dialog-account-detail';
import { ResourceDialogComponent } from './admin-dialogs/resource-dialog/resource-dialog';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { ExpenseDialogInLayoutComponent } from './admin-dialogs/expense-dialog-in-layout/expense-dialog-in-layout';
import { SftSwitchButtonModule } from 'src/stories/components/switch/switch.module';
import { ProjectDialogAccountsDetailComponent } from './admin-dialogs/project-dialog-account-detail/project-dialog-account-detail.component';
import { ProjectResourceDialogAccountDetailsComponent } from './admin-dialogs/project-resource-dialog-account-detail/project-resource-dialog-account-detail.component';
import { UserManagementDialogComponent } from './admin-dialogs/user-management-dialog/user-management-dialog.component';
import { CoreTeamDailogComponent } from './admin-dialogs/core-team-dialog/core-team-dialog';
import { CostCenterDailogComponent } from './admin-dialogs/cost-center-dialog/cost-center-dialog';
import { InvoicingExpenseDialogComponent } from './admin-dialogs/invoicing-expense-dialog/invoicing-expense-dialog.component';
import { ChangePasswordtDialogComponent } from './admin-dialogs/change-password-dialog/change-password-dialog.component';
import { CandidateDailogComponent } from './admin-dialogs/candidate-dialog/candidate-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PositionJobDetailsDialogComponent } from './customer-dialogs/position-job-detail/position-job-detail.component';
import { ViewFeedbackDialogComponent } from './view-feedback-dialog/view-feedback-dialog.component';
import { ExpenseDialogComponent } from './admin-dialogs/expense-dialog/expense-dialog.component';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';
import { SftInputModule } from 'src/stories/components/Input/input.module';
import { SftButtonModule } from 'src/stories/components/button/button.module';
import { SftRadioButtonModule } from 'src/stories/components/radiobutton/radio.module';
import { OffBoardConfirmationDialogComponent } from './admin-dialogs/off-board-confirmation-dialog/off-board-confirmation-dialog.component';
import { EditProfileDailogComponent } from './admin-dialogs/edit-profile-dialog/edit-profile-dialog.component';
import { ResetPasswordDialogComponent } from './admin-dialogs/reset-password-dialog/reset-password-dialog.component';
import { GenerateInvoiceDialogComponent } from './admin-dialogs/generate-invoice-dialog/generate-invoice-dialog.component';
import { InvoiceDateDialogComponent } from './admin-dialogs/invoice-date-dialog/invoice-date-dialog.component';
import { InvoiceGenerationConfirmationDialogComponent } from './admin-dialogs/invoice-generation-confirmation-dialog/invoice-generation-confirmation-dialog';
import { LoaderComponent } from '../loader/loader.component';
import { PaymentReceivedDialogComponent } from './admin-dialogs/payment-received-dialog/payment-received-dialog.component';
import { InvoiceDateFixedDialogComponent } from './admin-dialogs/invoice-date-fixed-dialog/invoice-date-fixed-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    SftButtonModule,
    SftInputModule,
    AngularMaterialModule,
    SftRadioButtonModule,
    ReactiveFormsModule,
    StorybookGlobalModule,
    SharedModule,
    SftSwitchButtonModule,
    DragDropModule,
    LoaderComponent,
  ],
  declarations: [
    ExpenseDialogComponent,
    ProjectDialogComponent,
    CostCenterDailogComponent,
    CostCenterDialogComponent,
    ResourceDialogComponent,
    CoreTeamDailogComponent,
    UserManagementDialogComponent,
    ChangePasswordtDialogComponent,
    ExpenseDialogInLayoutComponent,
    ProjectDialogAccountsDetailComponent,
    ProjectResourceDialogAccountDetailsComponent,
    CandidateDailogComponent,
    PositionJobDetailsDialogComponent,
    InvoicingExpenseDialogComponent,
    ViewFeedbackDialogComponent,
    OffBoardConfirmationDialogComponent,
    EditProfileDailogComponent,
    ResetPasswordDialogComponent,
    GenerateInvoiceDialogComponent,
    InvoiceDateDialogComponent,
    InvoiceGenerationConfirmationDialogComponent,
    PaymentReceivedDialogComponent,
    InvoiceDateFixedDialogComponent,
  ],
  exports: [
    ExpenseDialogComponent,
    ProjectDialogComponent,
    CostCenterDailogComponent,
    CostCenterDialogComponent,
    CoreTeamDailogComponent,
    ResourceDialogComponent,
    ExpenseDialogInLayoutComponent,
    ProjectDialogAccountsDetailComponent,
    UserManagementDialogComponent,
    ProjectResourceDialogAccountDetailsComponent,
    InvoicingExpenseDialogComponent,
  ],
})
export class DialogModule {}
