<div class="modal-wrapper p-6" [formGroup]="expenseForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">
        {{ isEditMode ? "Edit Recurring Expense" : "Add Recurring Expense" }}
      </h2>
      <p class="textXS mt-2 text">Enter expense details</p>
    </div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <input formControlName="expenseID" type="hidden" />
  <div class="content-wrapper my-4">
    <div class="input-wrapper mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Cost Center <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-select placeholder="Select" formControlName="cost_centerID">
          <mat-option
            *ngFor="let cost of costList"
            [value]="cost.cc_cost_centerID"
            >{{ cost.cc_center_name }} | {{ cost.cc_code.toUpperCase() }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div
        *ngIf="
          expenseForm.get('cost_centerID')?.touched ||
          expenseForm.get('cost_centerID')?.dirty
        "
      >
        <div
          class="required-star mt-1"
          *ngIf="
            !expenseForm.get('cost_centerID')?.hasError('required') &&
            expenseForm.get('cost_centerID')?.hasError('whitespace')
          "
        >
          Field cannot be empty or contain only whitespace.
        </div>
      </div>
    </div>
    <div class="col s12 m6 l6">
      <p class="mat-body-1 text mb-1 input-label">
        Expense Name <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          appCapitalizeFirstLetter
          matInput
          placeholder="Enter here"
          formControlName="name"
        />
      </mat-form-field>
      <div
        *ngIf="
          expenseForm.get('name')?.touched || expenseForm.get('name')?.dirty
        "
      >
        <div
          class="required-star mt-1"
          *ngIf="
            !expenseForm.get('name')?.hasError('required') &&
            expenseForm.get('name')?.hasError('whitespace')
          "
        >
          Field cannot be empty or contain only whitespace.
        </div>
      </div>
    </div>
    <div class="mb-4 mt-4">
      <span class="textXS">Expense type</span>
      <mat-radio-group aria-label="Select an option" formControlName="type">
        <mat-radio-button
          value="one_time"
          [checked]="expenseForm.get('type')?.value === 'one_time'"
          class="ml-2"
          >One Time</mat-radio-button
        >
        <mat-radio-button
          value="recurring"
          [checked]="expenseForm.get('type')?.value === 'recurring'"
          class="ml-2"
          >Recurring</mat-radio-button
        >
      </mat-radio-group>
    </div>

    <div class="col s12 m6 l6 mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Quantity <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          matInput
          placeholder="Enter here"
          formControlName="quantity"
          appNumericInput
          (numericInputValidityChange)="
            handleNumericInputValidityChange('quantity', $event)
          "
        />
      </mat-form-field>
      <span class="required-star" *ngIf="!numericInputValidity['quantity']"
        >Please enter a numeric value.</span
      >
      <span
        class="required-star"
        *ngIf="
          expenseForm.get('quantity')?.invalid &&
          (expenseForm.get('quantity')?.touched ||
            expenseForm.get('quantity')?.dirty) &&
          numericInputValidity['quantity']
        "
      >
        Field is required.
      </span>
    </div>
    <div class="col s12 m6 l6 mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Expense Amount {{ currency ? " (" + currency + ")" : "" }}
        <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          matInput
          placeholder="Enter here"
          formControlName="expenseAmount"
          appNumericInput
          (numericInputValidityChange)="
            handleNumericInputValidityChange('expenseAmount', $event)
          "
        />
      </mat-form-field>
      <span class="required-star" *ngIf="!numericInputValidity['expenseAmount']"
        >Please enter a numeric value.</span
      >
      <span
        class="required-star"
        *ngIf="
          expenseForm.get('expenseAmount')?.invalid &&
          (expenseForm.get('expenseAmount')?.touched ||
            expenseForm.get('expenseAmount')?.dirty) &&
          numericInputValidity['expenseAmount']
        "
      >
        Field is required.
      </span>
    </div>
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      class="mr-3 header-button"
      [label]="'Cancel'"
      [color]="'primary'"
      [variant]="'stroked'"
      [size]="'large'"
      (click)="close()"
    ></sft-button>
    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="isEditMode ? 'Update' : 'Save'"
      [color]="'primary'"
      [variant]="'flat'"
      [size]="'large'"
      [disable]="isButtonDisabled"
      (click)="isButtonDisabled ? null : onSubmit()"
    ></sft-button>
  </div>
</div>
