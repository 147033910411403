<app-loader *ngIf="loading"></app-loader>
<div
  class="modal-wrapper p-4 generate-invoice"
  *ngIf="!loading"
  [formGroup]="milestonesForm"
>
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">Generate Invoice</h2>
      <p class="textXS mt-2 text">Select Milestone of the project</p>
    </div>
    <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
  </div>
  <div class="content-wrapper my-4" formArrayName="milestones">
    <div
      class="p-4 milestone-wrapper mb-1"
      *ngFor="let milestone of milestones.controls; let i = index"
      [formGroupName]="i"
    >
      <div class="d-flex justify-between mb-2">
        <p class="mat-body-1 text-black">Milestone {{ i + 1 }}</p>
        <div class="d-flex">
          <ng-container
            *ngIf="
              milestone.get('invoice')?.value === null;
              else modifyTemplate
            "
          >
            <a
              href="javascript:void(0)"
              (click)="onActionClick(i, 'generate')"
              *ngIf="milestone.get('invoice')?.value === null"
              style="text-decoration: underline; color: #30a4dc"
            >
              <span
                class="d-block fw-500 textS primary-light-text border-right pr-2 mb-3 text-end mr-2"
              >
                Generate Invoice
              </span>
            </a>
          </ng-container>
          <ng-template #modifyTemplate>
            <a
              href="javascript:void(0)"
              *ngIf="
                !milestone.enabled && milestone.get('invoice')?.value !== null
              "
              (click)="enableMilestoneForm(i)"
              style="text-decoration: underline; color: #30a4dc"
            >
              <span
                class="d-block fw-500 textS primary-light-text border-right pr-2 mb-3 text-end mr-2"
              >
                Modify
              </span>
            </a>

            <!-- Generate Button to submit form -->
            <a
              href="javascript:void(0)"
              *ngIf="
                milestone.enabled && milestone.get('invoice')?.value !== null
              "
              (click)="onActionClick(i, 'modify')"
              style="text-decoration: underline; color: #30a4dc"
            >
              <span
                class="d-block fw-500 textS primary-light-text border-right pr-2 mb-3 text-end mr-2"
              >
                Generate Invoice
              </span>
            </a>
          </ng-template>
          <div class="billing unbilled h-18">
            <span class="d-block textS fw-500 warning-text">{{ status }}</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col s12 m6 l4">
          <p class="mat-body-1 text mb-1 input-label">
            Name <span class="required-star">*</span>
          </p>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input
              matInput
              placeholder="Enter here"
              formControlName="mileStoneName"
            />
          </mat-form-field>
        </div>
        <div class="col s12 m6 l3">
          <p class="mat-body-1 text mb-1 input-label">
            Price (USD) <span class="required-star">*</span>
          </p>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input matInput placeholder="Enter price" formControlName="price" />
          </mat-form-field>
        </div>
        <div class="col s12 m6 l4">
          <p class="mat-body-1 text mb-1 input-label">
            Due Date <span class="required-star">*</span>
          </p>
          <mat-form-field appearance="outline" subscriptSizing="dynamic">
            <input
              matInput
              [matDatepicker]="startpicker"
              placeholder="Enter due date"
              formControlName="dueDate"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="startpicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startpicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
