<div class="modal-wrapper p-4">
  <div class="modal-header d-flex justify-between">
    <div class="mb-2">
      <h2 class="mat-subtitle-1 mb-0 text">Invoice Generated Successfully</h2>
    </div>
    <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
  </div>
  <div class="content-wrapper mb-6">
    <span class="text textS"
      >Invoice successfully generated and saved in the Invoicing menu</span
    >
  </div>
  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [label]="'Close'"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
      (click)="onNoClick()"
    ></sft-button>
  </div>
</div>
