<mat-drawer-container
  class="pannel-container resource sidebar resource-modal-wrapper unset h-unset overflow-unset"
  [hasBackdrop]="true"
>
  <mat-drawer
    #drawer
    mode="side"
    position="end"
    [opened]="isOpen"
    (openedChange)="onDrawerOpenedChange($event)"
  >
    <div class="modal-header d-flex justify-between resource">
      <h2 class="mat-subtitle-1 mb-0 grey-text">Generate Project Invoice</h2>
      <mat-icon class="pointer" (click)="drawer.toggle()">close</mat-icon>
    </div>
    <app-loader *ngIf="loading"></app-loader>

    <ng-container *ngIf="!loading">
      <div
        class="content-wrapper resource-info resource-listing invoice-listing"
      >
        <ng-container *ngIf="!showInvoiceDetails">
          <h3 class="textS text mb-8">Resources assign to this project</h3>
          <div
            class="d-flex justify-between mb-6 invoice-term-details body-bg p-2"
          >
            <span class="textXS d-block text-black"
              >Billing cycle date:
              {{ billingCycle ? (billingCycle | date : "dd/MM/yyyy") : "--" }}
            </span>
            <span class="textS d-block text-black"
              >Credit Term: {{ creditTerm }} Days</span
            >
          </div>
          <div class="input-wrapper mb-4 border-line-invoice pb-6">
            <p class="mat-body-1 text mb-1 font-weight-500 input-label row">
              Year <span class="required-star">*</span>
            </p>
            <mat-form-field
              appearance="outline"
              subscriptSizing="dynamic"
              class="calender-field"
            >
              <mat-select
                placeholder="Select"
                [(value)]="selectedYear"
                (selectionChange)="onYearChange($event.value)"
              >
                <mat-option *ngFor="let year of availableYears" [value]="year">
                  {{ year }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <ng-container *ngIf="invoicingList.length > 0; else noInvoicesFound">
            <div
              class="account-detail-table invoice-detail resource-pagination w-full overflow-y-hidden"
            >
              <div class="listing-info-detail overflow-scroll">
                <div
                  *ngFor="let invoice of invoicingList"
                  class="d-flex justify-between border-line-invoice pb-3 mb-3"
                >
                  <div>
                    <p class="mat-body-1 mb-2 fw-500">
                      {{ invoice.invoice_number }}
                      <span class="text-black textS">
                        ({{ formatInvoiceNumberDate(invoice) }})
                      </span>
                    </p>
                    <span
                      *ngIf="invoice.invoice_date != null"
                      class="text-black textS d-block"
                    >
                      <b>INV</b>
                      {{ invoice.invoice_date | date : "MMM dd/yyyy" }} -
                      <b>DUE</b>
                      {{ invoice.due_date | date : "MMM dd/yyyy" }}
                    </span>
                  </div>

                  <div>
                    <a
                      *ngIf="invoice.is_generated !== 'true'"
                      href="javascript:void(0)"
                      (click)="
                        toggleGenerateInvoiceDetails(false, invoice?.invoiceID)
                      "
                      style="text-decoration: underline; color: #30a4dc"
                    >
                      <span
                        class="d-block fw-500 textS primary-light-text mb-3 ml-3"
                      >
                        Generate Invoice
                      </span>
                    </a>
                    <a
                      *ngIf="
                        invoice.is_generated === 'true' &&
                        invoice.invoice_status !== 'received'
                      "
                      href="javascript:void(0)"
                      (click)="
                        toggleModifyInvoiceDetails(false, invoice?.invoiceID)
                      "
                      style="text-decoration: underline; color: #30a4dc"
                    >
                      <span
                        class="d-block fw-500 textS primary-light-text mb-3"
                      >
                        Modify
                      </span>
                    </a>

                    <div class="billing">
                      <span
                        class="candidate-status justify-center d-flex align-center textXS fw-500 px-2 table-chip"
                        [ngClass]="{
                          'info-text  info-light-bg':
                            invoice.invoice_status === 'draft',
                          'warning-light-bg warning-text':
                            invoice.invoice_status === 'received',
                          'success-text success-light-bg':
                            invoice.invoice_status === 'sent',
                          'closed-text-color grey-text':
                            invoice.invoice_status === 'void'
                        }"
                      >
                        {{
                          getFormattedStatus(invoice.invoice_status | titlecase)
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <mat-paginator
                class="action-table-pagination w-unset z-1"
                [length]="totalRecords"
                [pageSize]="limit"
                [pageIndex]="pageNumber"
                [pageSizeOptions]="[25, 50, 75, 100]"
                aria-label="Select page"
                (page)="onPageChange($event)"
              ></mat-paginator>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #noInvoicesFound>
          <p class="text-center textS text-muted required-star">
            No invoices found
          </p>
        </ng-template>

        <ng-container *ngIf="showInvoiceDetails">
          <app-invoice-details
            [invoiceID]="selectedInvoiceID"
            [invoiceItemId]="selectedInvoiceItemID"
            [projectResourceCheck]="projectResoourceCheck"
            [projectType]="type"
            [modifyTriggered]="modifyTriggered"
            [generateTriggered]="generateTriggered"
            [billingCycle]="billingCycle"
            [creditTerm]="creditTerm"
            (goBack)="toggleInvoiceDetails(true)"
          ></app-invoice-details>
        </ng-container>
      </div>
    </ng-container>
  </mat-drawer>
</mat-drawer-container>
