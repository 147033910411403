import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';

interface DialogData {
  message: string;
  isDeleteAction: boolean;
  core_teamID: string;
  offBoardDate?: Date;
  element?: any;
}

@Component({
  selector: 'app-off-board-confirmation-dialog',
  templateUrl: './off-board-confirmation-dialog.component.html',
})
export class OffBoardConfirmationDialogComponent {
  offBoardDate = new FormControl(this.data.offBoardDate ? new Date(this.data.offBoardDate) : null);

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if (data.offBoardDate) {
      this.offBoardDate.setValue(new Date(data.offBoardDate));
    }
  }

  @Output() confirmed: EventEmitter<{ confirmed: boolean; date: Date | null }> =
    new EventEmitter<{ confirmed: boolean; date: Date | null }>();
  @Output() canceled = new EventEmitter<void>();

  onYesClick(): void {
    this.confirmed.emit({ confirmed: true, date: this.offBoardDate.value });
  }

  onNoClick(): void {
    this.canceled.emit();
  }
}
