import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { PageEvent } from '@angular/material/paginator';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { environment } from 'src/environment/environment';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export interface PeriodicElement {
  accountName: string;
  softobizAccountManager: string;
  contractStartDate: string;
  clientSPOC: string;
  currency: string;
  lastupdated: string;
  action: string;
  accountId: string;
}

@Component({
  selector: 'app-list-account',
  templateUrl: './account-list.component.html',
})
export class AccountListComponent implements OnInit {
  dataSource = new MatTableDataSource<PeriodicElement>([]);
  hasMoreRecords!: boolean;
  element: any;
  loading: boolean = false;
  baseUrl = environment.apiUrl;
  lastSortBy: string = '';
  showTooltip: boolean = false;
  userID: string | null = null;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder
  ) {}

  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit(): void {
    this.sortBy = 'accountName';
    this.orderBy = 'DESC';
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      this.userID = user.userID;
      this.showTooltip = user.newAccountTour === false;
    }
    this.getAccountListData(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
    this.form = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchAccountList();
  }

  closeTooltip(): void {
    const userData = localStorage.getItem('user');
    if (userData) {
      const user = JSON.parse(userData);
      const newAccountTour = true;

      this.accountService.updateToolTip(newAccountTour, user.userID).subscribe({
        next: () => {
          this.showTooltip = false;
          user.newAccountTour = newAccountTour;
          localStorage.setItem('user', JSON.stringify(user));
        },
        error: (error) => {
          console.error('Error updating tooltip status:', error);
        },
      });
    }
  }

  createAccount(): void {
    this.router.navigate(['/account/create']);
  }

  accountSettings(accountId: string): void {
    this.router.navigate(['/account/view', accountId]);
  }

  setElement(selectedElement: any): void {
    this.element = selectedElement;
  }

  displayedColumns: string[] = [
    'accountName',
    'softobizAccountManager',
    'contractStartDate',
    'clientSPOC',
    'currency',
    'lastupdated',
    'action',
  ];

  accountList: any[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'accountName' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  form!: FormGroup;
  isAscendingSort: boolean = true;
  totalRecords: number = 0;

  onSort(event: Sort): void {
    const sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.accountService
      .getAccountList(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy
      )
      .subscribe({
        next: (data: any) => {
          this.accountList = data?.data?.records || [];
          this.totalRecords = data?.data?.count || 0;
          this.dataSource.data = this.accountList;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Account List request:', error);
        },
      });
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;

    this.getAccountListData(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy || 'ASC'
    );
  }

  getLogoUrl(accountId: string): string {
    const account = this.accountList.find((acc) => acc.accountID === accountId);
    if (account && account.logo) {
      return `${this.baseUrl}/files/${account.logo}`;
    } else {
      return '/images/profile.jpg';
    }
  }

  getDefaultLogoText(accountName: string): string {
    if (!accountName) return '';

    const firstChar = accountName.charAt(0);
    const indexOfFirstSpace = accountName.indexOf(' ');
    let secondChar = '';
    if (
      indexOfFirstSpace !== -1 &&
      indexOfFirstSpace < accountName.length - 1
    ) {
      secondChar = accountName.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  getAccountListData(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: 'accountName' | 'createdAt' | 'modifiedAt',
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.loading = true;
    this.accountService
      .getAccountList(pageNumber, limit, search, sortBy, orderBy)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.accountList = data?.data?.records || [];
          this.totalRecords = data?.data?.count || 0;
          this.hasMoreRecords =
            this.pageNumber * this.limit + this.accountList.length <
            this.totalRecords;
          this.dataSource = new MatTableDataSource<PeriodicElement>(
            this.accountList.map((account: any) => ({
              accountId: account.accountId,
              accountName: account.accountName,
              addressLine1: account.addressLine1,
              softobizAccountManager: account.softobizAccountManager,
              contractStartDate: account.contractStartDate,
              contractReviewDate: account.contractReviewDate,
              clientSPOC: account.clientSPOC,
              currency: account.currency,
              lastupdated: account.lastupdated,
              action: '',
            }))
          );
          this.accountList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error in Account List request:', error);
          this.loading = false;
        },
      });
  }

  searchAccountList(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getAccountListData(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy || 'ASC'
        );
      });
  }

  newAccountWhenEmptyData() {
    this.router.navigate(['/account/create']);
  }
}
