import { Component, Inject, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, debounceTime, takeUntil, tap } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { CoreTeamService } from 'src/app/admin-portal/modules/core-team/services/core-team.service';
import { CostCenterService } from 'src/app/admin-portal/modules/cost-center/services/cost-center.service';
import { ResourceService } from 'src/app/admin-portal/modules/resource/services/resource.service';

@Component({
  selector: 'app-dialog-editcore-team',
  templateUrl: './core-team-dialog.html',
})
export class CoreTeamDailogComponent {
  resourceName!: string;
  coreTeamForm!: FormGroup;
  isButtonDisabled: boolean = true;
  isEditMode: boolean = false;
  costList: any[] = [];
  isSubmitting: boolean = false;
  resourceID: any;
  cost_centerID: any;
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'createdAt' = 'createdAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  costModal: string | undefined = undefined;
  resource: any[] = [];
  resourceList: any[] = [];
  filteredResources: any[] = [];
  noResourcesFound: boolean = false;
  selectedResourceId: string = '';
  showOperationalCostInput = false;
  currency: string | undefined;
  errorMessage: string | null = null;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public matDialog: MatDialogRef<CoreTeamDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private coreTeamService: CoreTeamService,
    private costCenterService: CostCenterService,
    private resourceService: ResourceService
  ) {}

  @ViewChild('autoResource') autoResource!: MatAutocomplete;

  ngOnInit(): void {
    this.isEditMode = this.data.isEditMode;
    this.initializeForm();
    this.loadCostCenters().subscribe();

    if (this.isEditMode && this.data.element) {
      this.patchFormValues(this.data.element);
      if (this.coreTeamForm.get('operationalCost')?.value > 0) {
        this.showOperationalCostInput = true;
      }
    }

    this.coreTeamForm.valueChanges.subscribe(() => {
      this.isButtonDisabled = !(
        this.coreTeamForm.valid &&
        (this.coreTeamForm.dirty || !this.showOperationalCostInput)
      );
    });

    this.coreTeamForm
      .get('resourceName')
      ?.valueChanges.pipe(debounceTime(300), takeUntil(this.onDestroy$))
      .subscribe((searchText: string) => {
        if (searchText && searchText.length >= 1) {
          this.loadResourceName(
            this.pageNumber,
            this.limit,
            searchText,
            'createdAt',
            'ASC'
          );
        } else {
          this.filteredResources = [];
        }
      });

    this.coreTeamForm
      .get('resourceName')
      ?.valueChanges.subscribe((selectedResourceName: any) => {
        const selectedResource = this.resourceList.find(
          (resource) => resource.rs_name === selectedResourceName
        );
        if (selectedResource) {
          this.coreTeamForm.patchValue({
            resourceSalary: selectedResource.resourceSalary,
            role: selectedResource.role,
          });
        }
      });
    this.currency = this.globalService.getCurrency();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  patchFormValues(element: any) {
    this.coreTeamForm.patchValue({
      resourceName: element.rs_name,
      resourceSalary: element.ct_resource_salary,
      role: element.ct_role,
      startDate: element.ct_start_date,
      operationalCost: element.ct_operational_cost,
      managementCost: element.managementCost,
      cost_centerID: element.cc_cost_centerID,
      resourceID: element.rs_resourceID,
      core_teamID: element.ct_core_teamID,
    });
  }

  toggleOperationalCostInput() {
    this.showOperationalCostInput = !this.showOperationalCostInput;

    if (this.showOperationalCostInput) {
      this.coreTeamForm
        .get('operationalCost')
        ?.setValidators([Validators.required, this.greaterThanZeroValidator]);
    } else {
      this.coreTeamForm.get('operationalCost')?.clearValidators();
    }
    this.coreTeamForm.get('operationalCost')?.updateValueAndValidity();
  }

  private greaterThanZeroValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const value = control.value;
    return value > 0 ? null : { greaterThanZero: true };
  }

  initializeForm() {
    this.coreTeamForm = this.formBuilder.group({
      resourceName: ['', Validators.required],
      resourceSalary: ['', Validators.required],
      role: ['', Validators.required],
      startDate: ['', Validators.required],
      operationalCost: ['', this.greaterThanZeroValidator],
      managementCost: [''],
      cost_centerID: ['', Validators.required],
      core_teamID: [''],
      resourceID: [''],
    });

    this.coreTeamForm
      .get('operationalCost')
      ?.setValidators((control: AbstractControl) => {
        if (this.showOperationalCostInput) {
          return Validators.required(control);
        } else {
          return null;
        }
      });
    this.coreTeamForm.get('role')?.disable();
    this.coreTeamForm.get('resourceSalary')?.disable();
    if (this.isEditMode) {
      this.coreTeamForm.get('resourceName')?.disable();
    }
  }

  loadCostCenters(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.costCenterService
      .getCostListByAccountId(
        0,
        MAX_LIMIT,
        '',
        'createdAt',
        'ASC',
        this.data.accountID
      )
      .pipe(
        tap((response: any) => {
          this.costList = response.data.records;
        })
      );
  }

  onSubmit(): void {
    if (
      this.coreTeamForm.valid &&
      this.areAllNumericInputsValid() &&
      !this.isSubmitting
    ) {
      this.isSubmitting = true;

      this.coreTeamForm.get('role')?.enable();
      this.coreTeamForm.get('resourceSalary')?.enable();

      const { resourceName, resourceSalary, role, startDate, operationalCost } =
        this.coreTeamForm.value;

      const cost_centerID = this.coreTeamForm.get('cost_centerID')?.value;
      const resourceID = this.selectedResourceId;

      // If in edit mode, update the core team
      if (this.isEditMode) {
        const core_teamID = this.coreTeamForm.get('core_teamID')?.value;
        const updatedCoreTeamData = {
          resourceSalary,
          role,
          startDate,
          cost_centerID,
          operationalCost: this.showOperationalCostInput
            ? operationalCost
            : '0',
          core_teamID,
        };

        this.coreTeamService
          .updateCoreTeam(updatedCoreTeamData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.isSubmitting = false;
              this.matDialog.close({ success: true });
            },
            error: (error: any) => {
              console.error('Error updating core team:', error);
              this.isSubmitting = false;
            },
          });
      } else {
        // If not in edit mode, create a new core team
        const newCoreTeamData = {
          resourceName,
          resourceSalary,
          role,
          startDate,
          resourceID,
          cost_centerID,
          operationalCost: this.showOperationalCostInput
            ? operationalCost
            : '0',
        };

        this.coreTeamService
          .createCoreTeam(newCoreTeamData)
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe({
            next: () => {
              this.isSubmitting = false;
              this.matDialog.close({ success: true });
            },
            error: (errorResponse: any) => {
              console.error('Error creating core team:', errorResponse);
              this.errorMessage = this.formatErrorMessage(errorResponse);
              this.isSubmitting = false;
            },
          });
      }
    } else {
      console.error('Invalid form data.');
      // Optional: Show an error message to the user
      this.errorMessage = 'Please fill in all required fields correctly.';
    }
  }

  private formatErrorMessage(error: any): string {
    let errorMessage =
      typeof error === 'string' ? error : error.message || 'An error occurred';
    errorMessage = errorMessage.replace(/^Error:\s*/, ''); // Remove "Error: " prefix
    errorMessage = errorMessage.replace(/^"|"$/g, ''); // Remove leading and trailing double quotes
    return errorMessage;
  }

  loadResourceName(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: 'createdAt',
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.resourceService
      .getResourceList(pageNumber, limit, search, sortBy, orderBy)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (data: any) => {
          this.resourceList = data?.data?.records;
          this.filteredResources = this.resourceList.slice();
        },
        error: (error: any) => {
          console.error('Error in fetching resource list:', error);
        },
      });
  }

  onResourceInput(event: any): void {
    const searchText: string = (event.srcElement.value || '').toLowerCase();
    if (searchText) {
      this.filteredResources = this.resourceList.filter((resource) => {
        return resource.name.toLowerCase().includes(searchText);
      });
      this.noResourcesFound = this.filteredResources.length === 0;
    } else {
      this.filteredResources = [];
      this.noResourcesFound = false;
    }
  }

  onResourceSelected(event: any): void {
    const selectedResourceName = event.option.id;
    const selectedResource = this.resourceList.find(
      (resource) => resource.re_resourceID === selectedResourceName
    );

    if (selectedResource) {
      this.selectedResourceId = selectedResource.re_resourceID;
      this.coreTeamForm.patchValue({
        resourceSalary: selectedResource.re_salary,
        role: selectedResource.re_role,
      });

      this.noResourcesFound = false;
    }
  }

  numericInputValidity: { [key: string]: boolean } = {
    operationalCost: true,
    managementCost: true,
  };

  handleNumericInputValidityChange(field: string, validity: boolean) {
    this.numericInputValidity[field] = validity;
  }

  areAllNumericInputsValid(): boolean {
    return Object.values(this.numericInputValidity).every((valid) => valid);
  }

  close() {
    this.matDialog.close();
  }
}
