<div class="modal-wrapper p-6" [formGroup]="coreTeamForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">
        {{ isEditMode ? "Edit Core Team" : "Add Core Team Member" }}
      </h2>
      <p class="textXS mt-2 text">
        {{ isEditMode ? "Update Member Details" : "Enter Member Details" }}
      </p>
    </div>
    <mat-icon class="pointer" (click)="close()">close</mat-icon>
  </div>
  <div class="content-wrapper my-4">
    <div class="input-wrapper mb-4">
      <p class="mat-body-1 text mb-1 font-weight-500 input-label">
        Cost Center <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-select placeholder="Select" formControlName="cost_centerID">
          <mat-option
            *ngFor="let cost of costList"
            [value]="cost.cc_cost_centerID"
            >{{ cost.cc_center_name }} | {{ cost.cc_code.toUpperCase() }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <input formControlName="core_teamID" type="hidden" />
    <input formControlName="cost_centerID" type="hidden" />
    <input formControlName="resourceID" type="hidden" />

    <div class="input-wrapper mb-4">
      <p class="mat-body-1 text mb-1 font-weight-500 input-label">
        Resource Name <span class="required-star">*</span>
      </p>
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        [style.cursor]="isButtonDisabled && isEditMode ? 'not-allowed' : ''"
      >
        <input
          appCapitalizeFirstLetter
          matInput
          placeholder="Enter here"
          formControlName="resourceName"
          [readonly]="isEditMode"
          [style.pointer-events]="isEditMode ? 'none' : 'auto'"
          *ngIf="isEditMode; else addResourceInput"
        />

        <ng-template #addResourceInput>
          <input
            matInput
            placeholder="Enter here"
            formControlName="resourceName"
            [matAutocomplete]="autoResource"
            (input)="onResourceInput($event)"
          />
        </ng-template>
      </mat-form-field>
      <mat-autocomplete
        #autoResource="matAutocomplete"
        (optionSelected)="onResourceSelected($event)"
      >
        <mat-option
          *ngFor="let resource of filteredResources"
          [id]="resource.re_resourceID"
          [value]="resource.re_name"
        >
          {{ resource.re_name }}
        </mat-option>
      </mat-autocomplete>
      <span class="required-star" *ngIf="noResourcesFound">
        No matching resources found.
      </span>
      <div *ngIf="errorMessage" class="required-star mt-2">
        <span *ngIf="coreTeamForm.get('resourceName')?.value">
          {{ coreTeamForm.get('resourceName')?.value }},
        </span>
        <span>{{ errorMessage }}</span>
      </div>
    </div>
    <div class="col s12 m6 l6 mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Resource Salary / Per Month {{ currency ? " (" + currency + ")" : ""
        }}<span class="required-star">*</span>
      </p>
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        style="cursor: not-allowed"
      >
        <input
          matInput
          placeholder="00.00"
          formControlName="resourceSalary"
          style="cursor: not-allowed"
        />
      </mat-form-field>
    </div>
    <div class="col s12 m6 l6 mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Role <span class="required-star">*</span>
      </p>
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic"
        style="cursor: not-allowed"
      >
        <input
          matInput
          placeholder="--"
          formControlName="role"
          style="cursor: not-allowed"
        />
      </mat-form-field>
    </div>
    <div class="input-wrapper">
      <p class="mat-body-1 text mb-1 input-label">
        Start Date <span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          matInput
          [matDatepicker]="startpicker"
          placeholder="dd/mm/yyyy"
          formControlName="startDate"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="startpicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startpicker></mat-datepicker>
      </mat-form-field>
      <span
        class="required-star"
        *ngIf="
          coreTeamForm.get('startDate')?.invalid &&
          (coreTeamForm.get('startDate')?.touched ||
            coreTeamForm.get('startDate')?.dirty)
        "
      >
        Field is required.
      </span>
    </div>
  </div>
  <div class="switch-wrapper core-team">
    <sft-switch-button
      [label]="'Does this resource incur operational costs?'"
      [disable]="false"
      [checked]="showOperationalCostInput"
      [color]="'primary'"
      [disableRipple]="false"
      [indeterminate]="true"
      [labelPosition]="'before'"
      (click)="toggleOperationalCostInput()"
    ></sft-switch-button>
  </div>

  <div *ngIf="showOperationalCostInput" class="mt-4">
    <p class="mat-body-1 text mb-1 input-label">Operational Cost</p>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <input
        matInput
        placeholder="Enter here"
        formControlName="operationalCost"
        appNumericInput
        (numericInputValidityChange)="
          handleNumericInputValidityChange('operationalCost', $event)
        "
      />
    </mat-form-field>
    <span class="required-star" *ngIf="!numericInputValidity['operationalCost']"
      >Please enter a numeric value.</span
    >
    <span
      class="required-star"
      *ngIf="
    coreTeamForm.get('operationalCost')?.hasError('required') &&
    (coreTeamForm.get('operationalCost')?.touched || coreTeamForm.get('operationalCost')?.dirty) &&
    numericInputValidity
  "
    >
      Field is required.
    </span>
    <span
      class="required-star"
      *ngIf="
    coreTeamForm.get('operationalCost')?.hasError('greaterThanZero') &&
    !coreTeamForm.get('operationalCost')?.hasError('required') &&
    (coreTeamForm.get('operationalCost')?.touched || coreTeamForm.get('operationalCost')?.dirty) &&
    numericInputValidity
  "
    >
      Operational cost must be greater than 0.
    </span>
  </div>

  <div class="modal-footer mt-2 d-flex justify-end">
    <sft-button
      [style.cursor]="isButtonDisabled ? 'not-allowed' : 'pointer'"
      [label]="isEditMode ? 'Update' : 'Save'"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
      [disable]="isButtonDisabled"
      (click)="isButtonDisabled ? null : onSubmit()"
    ></sft-button>
  </div>
</div>
