<h1 mat-dialog-title>Please crop your logo</h1>
<div mat-dialog-content>
  <image-cropper
    [maintainAspectRatio]="true"
    [aspectRatio]="data.width / data.height"
    [resizeToHeight]="data.height"
    [resizeToWidth]="data.width"
    [onlyScaleDown]="true"
    [imageFile]="data.image"
    (imageCropped)="imageCropped($event)"
  ></image-cropper>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="result()" cdkFocusInitial>Save</button>
</div>
