<div
  class="main-content body-bg p-6 account-detail-module account-style account-horizontal-layout"
>
  <p class="d-flex align-center textXS fw-400 pb-4">
    <mat-icon class="pr-2" (click)="goBack()" style="cursor: pointer"
      >arrow_back</mat-icon
    >Back to Team List / Account Details
  </p>
  <div>
    <div class="expansion-wrapper pb-4 hidden">
      <mat-accordion>
        <mat-expansion-panel
          (opened)="panelOpenState.set(true)"
          (closed)="panelOpenState.set(false)"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="d-flex justify-between my-4 w-full horizontal-col">
                <div class="d-flex">
                  <div class="customer-logo">
                    <div
                      class="avatar-md mr-2"
                      style="width: 50px; height: 50px; overflow: hidden"
                    >
                      <img *ngIf="logo" [src]="logo" alt="Logo" />
                      <div
                        *ngIf="!logo"
                        class="table-avatar mr-2"
                        style="
                          background-color: #005f8e;
                          color: #fff;
                          border-radius: 50%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          width: 100%;
                          height: 100%;
                        "
                      >
                        {{ getDefaultLogoText(accountName) }}
                      </div>
                    </div>
                  </div>
                  <div class="ml-2 address-info">
                    <h2 class="textl sec-text-color fw-600">
                      {{ accountName }}
                    </h2>
                    <span
                      class="textS"
                      title=" {{ fullAddress }} {{ city }}, {{ state }},
                    {{ country }} - {{ zipCode }}"
                    >
                      {{ fullAddress }} {{ city }}, {{ state }}, {{ country }} -
                      {{ zipCode }}</span
                    >
                  </div>
                </div>
                <div class="horizontal-information">
                  <h3 class="sec-text-color fw-500 mb-2 textS">Client SPOC</h3>
                  <span class="grey-text textS">{{ Cspoc }}</span>
                </div>
                <div class="horizontal-information start-date">
                  <h3 class="sec-text-color fw-500 mb-2 textS">
                    Contract Start Date
                  </h3>
                  <span class="grey-text textS">
                    {{ startDate | date : "d MMM y" }}</span
                  >
                </div>
                <div class="horizontal-information date-col">
                  <h3 class="sec-text-color fw-500 mb-2 textS">
                    Contract Review Date
                  </h3>
                  <span class="grey-text textS">
                    {{ reviewDate | date : "d MMM y" }}</span
                  >
                </div>
                <div class="horizontal-information currency-data">
                  <h3 class="sec-text-color fw-500 mb-2 textS">Currency</h3>
                  <span class="grey-text textS">{{ currency }}</span>
                </div>
                <div class="edit-button" (click)="editAccount()">
                  <img src="images/edit.png" alt="" />
                </div>
                <div class="button-wrapper currency d-none text-end">
                  <sft-button
                    [label]="'Edit'"
                    [color]="'primary'"
                    [variant]="'stroked'"
                    [disable]="false"
                    [icon]="'home'"
                    [disableRipple]="false"
                    [size]="'medium'"
                    (click)="editAccount()"
                  ></sft-button>
                </div>
                <div class="horizontal-information process-billing">
                  <h3 class="sec-text-color fw-500 mb-2 textS">
                    Billing Cycle
                  </h3>
                  <span class="grey-text textS">
                    {{
                      billingCycle ? (billingCycle | date : "d MMM y") : "--"
                    }}
                  </span>
                </div>
                <div class="button-wrapper d-none billing-button text-end">
                  <sft-button
                    [label]="'Edit'"
                    [color]="'primary'"
                    [variant]="'stroked'"
                    [disable]="false"
                    [icon]="'home'"
                    [disableRipple]="false"
                    [size]="'medium'"
                    (click)="editAccount()"
                  ></sft-button>
                </div>
                <div class="horizontal-information credit-info">
                  <h3 class="sec-text-color fw-500 mb-2 textS">Credit Terms</h3>
                  <span class="grey-text textS">{{ creditTerm || "--" }}</span>
                </div>
                <div class="horizontal-information timezone">
                  <h3 class="sec-text-color fw-500 mb-2 textS">Time Zone</h3>
                  <span class="grey-text textS" title="{{ timeZone }}">{{
                    timeZone
                  }}</span>
                </div>
                <div class="button-wrapper hide-button">
                  <sft-button
                    [label]="'Edit'"
                    [color]="'primary'"
                    [variant]="'stroked'"
                    [disable]="false"
                    [icon]="'home'"
                    [disableRipple]="false"
                    [size]="'medium'"
                    (click)="editAccount()"
                  ></sft-button>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="d-flex layout-detail-info">
            <div class="horizontal-information process-billing d-none Cycle">
              <h3 class="sec-text-color fw-500 mb-2 textS">Billing Cycle</h3>
              <span class="grey-text textS">
                {{ billingCycle ? (billingCycle | date : "d MMM y") : "--" }}
              </span>
            </div>
            <div class="horizontal-information credit-info d-none">
              <h3 class="sec-text-color fw-500 mb-2 textS">Credit Terms</h3>
              <span class="grey-text textS">{{ creditTerm || "--" }}</span>
            </div>
            <div class="horizontal-information timezone d-none">
              <h3 class="sec-text-color fw-500 mb-2 textS">Time Zone</h3>
              <span class="grey-text textS" title="{{ timeZone }}">{{
                timeZone
              }}</span>
            </div>
            <div class="manager-info account-info info-hide">
              <h3 class="textS sec-text-color fw-500 mb-2">Account Manager</h3>
              <p class="textS grey-text">{{ accountManager }}</p>
            </div>
            <div class="finance-info detail d-none">
              <h3 class="textS sec-text-color fw-500 mb-2">
                Financial Representative
              </h3>
              <p class="textS grey-text" title="{{ financialName }}">
                {{ financialName }}
              </p>
            </div>
          </div>

          <div class="d-flex pl-15 mt-8 hidden-layout detailed-layout">
            <div class="manager-info">
              <h3 class="textS sec-text-color fw-500 mb-2">Account Manager</h3>
              <p class="textS grey-text">{{ accountManager }}</p>
            </div>
            <div class="finance-info">
              <h3 class="textS sec-text-color fw-500 mb-2">
                Financial Representative
              </h3>
              <p class="textS grey-text">{{ financialName }}</p>
            </div>

            <div class="status-info">
              <h3 class="textS sec-text-color fw-500 mb-2">Last Updated</h3>
              {{ financialName }}
              <p class="textS grey-text">
                {{ lastUpdate | date : "d MMM y" }}
                {{ lastUpdate | date : "h:mm a" : "UTC" }}
              </p>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div>
      <div class="account-detail-tab w-unset">
        <div class="p-6">
          <mat-tab-group
            [(selectedIndex)]="selectedTabIndex"
            (selectedTabChange)="onTabChange($event)"
            animationDuration="0ms"
          >
            <!-- COST CENTER -->
            <mat-tab label="Cost Center Management">
              <div
                *ngIf="
                  costList.length > 0 ||
                  costCenterForm.get('searchQuery')?.value
                "
                class="form-and-table-container"
              >
                <form [formGroup]="costCenterForm">
                  <div class="pb-6 pt-6">
                    <div
                      class="d-flex justify-between align-center account-detail-actions"
                    >
                      <div class="search-block">
                        <mat-form-field
                          appearance="outline"
                          class="search-input"
                        >
                          <input
                            type="text"
                            matInput
                            placeholder="Search"
                            formControlName="searchQuery"
                            (keydown.enter)="$event.preventDefault()"
                          />
                          <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                      </div>
                      <div class="d-flex align-center">
                        <sft-button
                          [label]="'Add Cost Center'"
                          [color]="'primary'"
                          [variant]="'flat'"
                          [disable]="false"
                          [icon]="'home'"
                          [disableRipple]="false"
                          [size]="'medium'"
                          (click)="openAddCostDialog()"
                        ></sft-button>
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  class="account-detail-table overflow-scroll table-container"
                >
                  <ng-container>
                    <app-loader *ngIf="loading"></app-loader>
                    <table
                      *ngIf="costList.length > 0 && !loading"
                      mat-table
                      [dataSource]="costList"
                      matSort
                      (matSortChange)="onSortChangeCostCenter($event)"
                    >
                      <ng-container matColumnDef="position">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="code"
                          class="code-col"
                        >
                          Cost Center Code
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{
                            element.cc_code
                              ? "" + element.cc_code.toUpperCase()
                              : "--"
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="name">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="centerName"
                          class="code-col"
                        >
                          Cost Center name
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          class="table-ellipse code-info-col"
                          title="{{ element.cc_center_name }}"
                        >
                          {{ element.cc_center_name | truncate : 10 || "--" }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="weight">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="centerLeader"
                          class="code-col leader"
                        >
                          Cost Center Leader
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          title="{{ element.cc_center_leader }}"
                          class="table-ellipse leader-col code-info-col"
                        >
                          {{ element.cc_center_leader | truncate : 10 || "--" }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="symbol">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="marginRate"
                          class="code-col rate"
                        >
                          Margin Rate (%)
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          title="{{ element.cc_margin_rate }}"
                        >
                          {{ element.cc_margin_rate | truncate : 10 || "--" }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="action">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          class="action-col"
                        >
                          Action
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element; let i = index"
                          class="text-center"
                        >
                          <mat-icon
                            [matMenuTriggerFor]="menuEdit"
                            style="cursor: pointer"
                            >more_vert</mat-icon
                          >
                          <mat-menu #menuEdit="matMenu" class="action-info">
                            <button
                              mat-menu-item
                              (click)="openEditCostDialog(element)"
                            >
                              Edit
                            </button>
                            <button
                              mat-menu-item
                              (click)="deleteCost(element?.cc_cost_centerID)"
                            >
                              Delete
                            </button>
                          </mat-menu>
                        </td>
                      </ng-container>
                      <tr
                        mat-header-row
                        class="account-detail-header"
                        *matHeaderRowDef="displayedColumns"
                      ></tr>
                      <tr
                        mat-row
                        class="account-detail-body"
                        *matRowDef="let row; columns: displayedColumns"
                      ></tr>
                    </table>
                  </ng-container>

                  <div
                    *ngIf="
                      costCenterForm.get('searchQuery')?.value &&
                      costList.length === 0
                    "
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                    "
                  >
                    <p class="required-star" style="font-size: 1.5rem">
                      No cost centers found.
                    </p>
                  </div>
                  <mat-paginator
                    *ngIf="costList.length > 0"
                    class="action-table-pagination w-full"
                    [length]="totalCostRecords"
                    [pageSize]="costCenterPageSize"
                    [pageIndex]="costCenterPageNumber"
                    [pageSizeOptions]="[25, 50, 75, 100]"
                    (page)="onCostCenterPageChange($event)"
                    aria-label="Select page"
                  >
                  </mat-paginator>
                </div>
              </div>
              <div
                *ngIf="costList.length === 0 && !loading"
                class="cost-info overflow-scroll h-full"
              >
                <div class="text-center mt-12 bgform-img">
                  <img src="images/Taxes.png" alt="form-bg" />
                </div>
                <div class="w-md m-auto">
                  <p class="mat-headline-5 text-center mb-4 mt-6">
                    No cost centers have been added yet
                  </p>
                  <span class="textS text-center mb-4 grey-text fw-400 d-block">
                    <span
                      class="textS text-center mb-4 grey-text fw-400 d-block"
                    >
                      Cost centers are essential for organizing expenses. Please
                      add a cost center to proceed with adding projects, as they
                      are required for effective project management.
                    </span>
                  </span>
                </div>
                <div class="text-center">
                  <sft-button
                    [label]="'Add Cost Center'"
                    [color]="'primary'"
                    [variant]="'flat'"
                    [disable]="false"
                    [icon]="'home'"
                    [disableRipple]="false"
                    [size]="'large'"
                    (click)="openAddCostDialog()"
                  ></sft-button>
                  <div class="d-flex align-center justify-center mt-12">
                    <div class="d-flex body-bg p-4">
                      <span
                        class="material-symbols-filled material-symbols-outlined"
                      >
                        info
                      </span>
                      <span class="textS ml-2 mb-4 grey-text fw-400"
                        >Before adding a project, ensure you've created a cost
                        center. <br />
                        Cost centers are necessary for effective project
                        management
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </mat-tab>

            <!-- CORE TEAM -->
            <mat-tab label="Core Team Management">
              <div class="form-and-table-container">
                <form [formGroup]="coreTeamForm">
                  <div class="pt-6">
                    <div
                      class="d-flex justify-between align-center account-detail-actions"
                    >
                      <div class="d-flex">
                        <div class="mr-2">
                          <mat-form-field
                            appearance="outline"
                            class="search-input"
                          >
                            <input
                              type="text"
                              matInput
                              placeholder="Search"
                              formControlName="searchQuery"
                              (keydown.enter)="$event.preventDefault()"
                            />
                            <mat-icon matSuffix>search</mat-icon>
                          </mat-form-field>
                        </div>
                        <div class="input-wrapper">
                          <mat-form-field
                            appearance="outline"
                            class="status-box project-box core-status"
                            subscriptSizing="dynamic"
                            [formGroup]="coreTeamForm"
                          >
                            <mat-select
                              placeholder="Active Resources"
                              formControlName="status"
                            >
                              <mat-option value="off_board"
                                >Off-Board Resource</mat-option
                              >
                              <mat-option value="active"
                                >Active Resources</mat-option
                              >
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="d-flex align-center">
                        <sft-button
                          [label]="'Add Core Team'"
                          [color]="'primary'"
                          [variant]="'flat'"
                          [disable]="false"
                          [icon]="'home'"
                          [disableRipple]="false"
                          [size]="'medium'"
                          (click)="openAddCoreTeamDialog()"
                        ></sft-button>
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  class="account-detail-table overflow-scroll table-container mt-6"
                >
                  <app-loader *ngIf="loading"></app-loader>
                  <ng-container *ngIf="!loading">
                    <div *ngIf="coreTeamList.length > 0">
                      <table
                        mat-table
                        [dataSource]="coreTeamList"
                        matSort
                        (matSortChange)="onSortChangeCoreTeam($event)"
                      >
                        <ng-container matColumnDef="name">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header="resourceName"
                            class="name-col"
                          >
                            Name
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element"
                            class="table-data code-info-col"
                            title="{{ element.rs_name }}"
                          >
                            {{ element.rs_name | truncate : 10 || "--" }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="costCenter">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header="centerName"
                            class="name-col"
                          >
                            Cost Center
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element"
                            class="table-data code-info-col"
                            title="{{ element.cc_center_name }}"
                          >
                            {{ element.cc_center_name | truncate : 10 || "--" }}
                            |
                            {{ element.cc_code || "--" }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="weight">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header="role"
                            class="name-col"
                          >
                            Role
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element"
                            class="table-data code-info-col"
                            title="{{ element.ct_role }}"
                          >
                            {{ element.ct_role | truncate : 10 || "--" }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="salary">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header="resourceSalary"
                            class="name-col"
                          >
                            Salary {{ currency ? " (" + currency + ")" : "" }}
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element"
                            class="table-data"
                            title="{{
                              element.ct_resource_salary !== null &&
                              isValidNumber(element.ct_resource_salary)
                                ? (element.ct_resource_salary
                                  | number : '1.2-2')
                                : '--'
                            }}"
                          >
                            {{
                              element.ct_resource_salary !== null &&
                              isValidNumber(element.ct_resource_salary)
                                ? (element.ct_resource_salary
                                  | number : "1.2-2")
                                : "--"
                            }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header="status"
                            class="name-col"
                          >
                            Status
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element"
                            class="table-data"
                          >
                            <div
                              *ngIf="
                                isCoreTeamStatusOffBoarded(element.ct_status)
                              "
                              class="closed-text-color grey-text w-fit px-2 table-chip"
                            >
                              {{
                                getCoreTeamFormattedStatus(element.ct_status)
                              }}
                            </div>
                            <div
                              *ngIf="isCoreTeamStatusActive(element.ct_status)"
                              class="success-text success-light-bg w-fit px-2 table-chip"
                            >
                              {{
                                getCoreTeamFormattedStatus(element.ct_status)
                              }}
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            mat-sort-header="startDate"
                            class="name-col"
                          >
                            Start Date
                          </th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.ct_start_date | date : "dd/MM/yyyy" }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                          <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="action-col"
                          >
                            Action
                          </th>
                          <td
                            mat-cell
                            *matCellDef="let element"
                            class="text-center"
                          >
                            <mat-icon
                              [matMenuTriggerFor]="menuEdit"
                              (click)="setElement(element)"
                              style="cursor: pointer"
                              >more_vert</mat-icon
                            >
                          </td>
                          <mat-menu #menuEdit="matMenu" class="action-info">
                            <button
                              mat-menu-item
                              (click)="openEditCoreTeamDialog(element)"
                            >
                              Edit
                            </button>
                            <button
                              mat-menu-item
                              (click)="
                                offBoardCoreTeamMember(
                                  element.ct_core_teamID,
                                  element
                                )
                              "
                            >
                              Off-Board
                            </button>
                          </mat-menu>
                        </ng-container>
                        <tr
                          mat-header-row
                          class="account-detail-header"
                          *matHeaderRowDef="displayedColumns1"
                        ></tr>
                        <tr
                          mat-row
                          class="account-detail-body"
                          *matRowDef="let row; columns: displayedColumns1"
                        ></tr>
                      </table>
                    </div>

                    <div
                      *ngIf="
                        coreTeamList.length === 0 &&
                        !coreTeamForm.get('searchQuery')?.value
                      "
                    >
                      <div class="cost-info h-full overflow-scroll">
                        <div class="text-center mt-12 bgform-img">
                          <img src="/images/programming.png" alt="form-bg" />
                        </div>
                        <div class="w-md m-auto">
                          <p class="mat-headline-5 text-center mb-4 mt-6">
                            No Core team Member Added yet!
                          </p>
                          <span class="textS text-center mb-4 d-block fw-400">
                            The project assigned to this account is currently
                            empty. Let's get started by adding a new project.
                          </span>
                        </div>
                        <div class="text-center">
                          <sft-button
                            [label]="'Add Core Team'"
                            [color]="'primary'"
                            [variant]="'flat'"
                            [disable]="false"
                            [icon]="'home'"
                            [disableRipple]="false"
                            [size]="'large'"
                            (click)="openAddCoreTeamDialog()"
                          ></sft-button>
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="
                        coreTeamForm.get('searchQuery')?.value &&
                        coreTeamList.length === 0
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                      "
                    >
                      <p class="required-star" style="font-size: 1.5rem">
                        No core team members found.
                      </p>
                    </div>

                    <div
                      *ngIf="
                        !coreTeamForm.get('searchQuery')?.value &&
                        coreTeamList.length === 0
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                      "
                    ></div>
                  </ng-container>
                  <mat-paginator
                    *ngIf="coreTeamList.length > 0"
                    class="action-table-pagination w-full"
                    [length]="totalCoreTeamRecords"
                    [pageSize]="coreTeamPageSize"
                    [pageIndex]="coreTeamPageNumber"
                    [pageSizeOptions]="[25, 50, 75, 100]"
                    (page)="onCoreTeamPageChange($event)"
                    aria-label="Select page"
                  ></mat-paginator>
                </div>
              </div>
            </mat-tab>

            <!-- PROJECTS -->
            <mat-tab label="Project Management">
              <div
                *ngIf="
                  projectList.length > 0 ||
                  projectForm.get('searchQuery')?.value
                "
                class="form-and-table-container"
              >
                <form [formGroup]="projectForm">
                  <div class="pt-6">
                    <div
                      class="d-flex justify-between align-center account-detail-actions"
                    >
                      <div class="search-block">
                        <mat-form-field
                          appearance="outline"
                          class="search-input"
                        >
                          <input
                            type="text"
                            matInput
                            placeholder="Search"
                            formControlName="searchQuery"
                            (keydown.enter)="$event.preventDefault()"
                          />
                          <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                      </div>
                      <div class="d-flex align-center">
                        <sft-button
                          [label]="'Add Project'"
                          [color]="'primary'"
                          [variant]="'flat'"
                          [disable]="false"
                          [icon]="'home'"
                          [disableRipple]="false"
                          [size]="'medium'"
                          (click)="openProjectDialog()"
                        ></sft-button>
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  class="account-detail-table overflow-scroll table-container mt-6 project-management"
                >
                  <app-loader *ngIf="loading"></app-loader>
                  <ng-container>
                    <table
                      *ngIf="projectList.length > 0 && !loading"
                      mat-table
                      [dataSource]="projectList"
                      matSort
                      (matSortChange)="onSortChangeProject($event)"
                    >
                      <ng-container matColumnDef="name">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="projectName"
                          class="project-col"
                        >
                          Name
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          class="table-data code-info-col"
                          title="{{ element.name }}"
                        >
                          {{ element.name | truncate : 10 || "--" }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="symbol">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="costModal"
                          class="project-col"
                        >
                          Cost Model
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          class="table-data"
                          title="  {{
                            element?.cost_modal === 'hourly'
                              ? 'Hourly'
                              : element?.cost_modal === 'fixed'
                              ? 'Fixed'
                              : element?.cost_modal === 'daily'
                              ? 'Daily'
                              : '--'
                          }}"
                        >
                          {{
                            element?.cost_modal === "hourly"
                              ? "Hourly"
                              : element?.cost_modal === "fixed"
                              ? "Fixed"
                              : element?.cost_modal === "daily"
                              ? "Daily"
                              : "--"
                          }}
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="date">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="startDate"
                          class="project-col"
                        >
                          Start Date
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.start_date | date : "dd/MM/yyyy" }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="action">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="status"
                          class="project-col"
                        >
                          Status
                        </th>
                        <td mat-cell *matCellDef="let element">
                          <div
                            *ngIf="isStatusOngoing(element.status)"
                            class="success-text success-light-bg w-fit px-2 table-chip"
                          >
                            {{ getFormattedStatus(element.status) }}
                          </div>

                          <div
                            *ngIf="isStatusOnHold(element.status)"
                            class="warning-light-bg warning-text w-fit px-2 table-chip"
                          >
                            {{ getFormattedStatus(element.status) }}
                          </div>

                          <div
                            *ngIf="isStatusCompleted(element.status)"
                            class="info-text info-light-bg w-fit px-2 table-chip"
                          >
                            {{ getFormattedStatus(element.status) }}
                          </div>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="resource">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="totalResource"
                          class="project-col"
                        >
                          Resources
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          class="info-text info-light-bg"
                        >
                          {{ element?.resourceCount }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="projectaction">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          class="cost-center-action"
                        >
                          Action
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          class="text-center"
                        >
                          <mat-icon
                            [matMenuTriggerFor]="menuProject"
                            (click)="setElement(element)"
                            style="cursor: pointer"
                            (click)="setElement(element)"
                          >
                            more_vert
                          </mat-icon>
                        </td>
                        <mat-menu #menuProject="matMenu" class="action-info">
                          <button
                            mat-menu-item
                            (click)="openEditProjectDialog(element)"
                          >
                            Edit
                          </button>
                          <button
                            mat-menu-item
                            (click)="deleteProject(element?.projectId)"
                          >
                            Delete
                          </button>
                          <button
                            mat-menu-item
                            (click)="openResourceDrawer(element?.projectId)"
                          >
                            Manage Resource
                          </button>
                          <button
                            mat-menu-item
                            (click)="handleProjectAction(element)"
                          >
                            Generate Invoice
                          </button>
                        </mat-menu>
                      </ng-container>
                      <tr
                        mat-header-row
                        class="account-detail-header"
                        *matHeaderRowDef="displayedColumns2"
                      ></tr>
                      <tr
                        mat-row
                        class="account-detail-body"
                        *matRowDef="let row; columns: displayedColumns2"
                      ></tr>
                    </table>
                    <div
                      *ngIf="
                        projectForm.get('searchQuery')?.value &&
                        projectList.length === 0
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                      "
                    >
                      <p class="required-star" style="font-size: 1.5rem">
                        No projects found.
                      </p>
                    </div>
                    <mat-paginator
                      *ngIf="projectList.length > 0"
                      class="action-table-pagination w-full"
                      [length]="totalProjectRecords"
                      [pageSize]="projectPageSize"
                      [pageIndex]="projectPageNumber"
                      [pageSizeOptions]="[25, 50, 75, 100]"
                      (page)="onProjectPageChange($event)"
                      aria-label="Select page"
                    >
                    </mat-paginator>
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="projectList.length === 0 && !loading"
                class="cost-info overflow-scroll h-full"
              >
                <div class="text-center mt-12 bgform-img">
                  <img src="/images/projectplanning.png" alt="form-bg" />
                </div>
                <div class="w-md m-auto">
                  <p class="mat-headline-5 text-center mb-4 mt-6">
                    No Projects Assigned Yet
                  </p>
                  <span class="textS text-center mb-4 d-block grey-text fw-400">
                    The project assigned to this account is currently empty.
                    Let's get started by adding a new project.
                  </span>
                </div>
                <div class="text-center">
                  <sft-button
                    [label]="'Add New Project'"
                    [color]="'primary'"
                    [variant]="'flat'"
                    [disable]="false"
                    [icon]="'home'"
                    [disableRipple]="false"
                    [size]="'large'"
                    (click)="openProjectDialog()"
                  ></sft-button>
                </div>
                <div class="d-flex align-center justify-center mt-16">
                  <div class="d-flex body-bg p-4">
                    <span
                      class="material-symbols-filled material-symbols-outlined"
                    >
                      info
                    </span>
                    <span class="textS ml-2 grey-text fw-400"
                      >Before adding resources, please create a project. Without
                      a
                      <br />
                      project, you can't add resources.
                    </span>
                  </div>
                </div>
              </div>
            </mat-tab>

            <!-- RECURRING EXPENSES -->
            <mat-tab label="Expense">
              <div
                *ngIf="
                  expenseList.length > 0 ||
                  expenseForm.get('searchQuery')?.value
                "
                class="form-and-table-container"
              >
                <form [formGroup]="expenseForm">
                  <div class="pb-6 pt-6">
                    <div
                      class="d-flex justify-between align-center account-detail-actions"
                    >
                      <div class="search-block">
                        <mat-form-field
                          appearance="outline"
                          class="search-input"
                        >
                          <input
                            type="text"
                            matInput
                            placeholder="Search"
                            formControlName="searchQuery"
                            (keydown.enter)="$event.preventDefault()"
                          />
                          <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                      </div>
                      <div class="d-flex align-center">
                        <sft-button
                          [label]="'Add Recurring Expense'"
                          [color]="'primary'"
                          [variant]="'flat'"
                          [disable]="false"
                          [icon]="'home'"
                          [disableRipple]="false"
                          [size]="'medium'"
                          (click)="openExpenseDialog()"
                        ></sft-button>
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  class="account-detail-table overflow-scroll table-container"
                >
                  <app-loader *ngIf="loading"></app-loader>
                  <ng-container>
                    <table
                      *ngIf="expenseList.length > 0 && !loading"
                      mat-table
                      [dataSource]="expenseList"
                      matSort
                      (matSortChange)="onSortChangeExpense($event)"
                      class="custom-action-table"
                    >
                      <ng-container matColumnDef="name">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="name"
                        >
                          Expense Name
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          class="table-ellipse expense-col code-info-col"
                          title="{{ element.name }}"
                        >
                          {{ element.roe_name | truncate : 10 || "--" }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="type">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="type"
                        >
                          Expense Type
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{
                            element.roe_type === "one_time"
                              ? "One Time"
                              : element.roe_type === "recurring"
                              ? "Recurring"
                              : "--"
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="quantity">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="quantity"
                        >
                          Quantity
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{ element.roe_quantity }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="amount">
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          mat-sort-header="expenseAmount"
                        >
                          Expense Amount
                          {{ currency ? " (" + currency + ")" : "" }}
                        </th>
                        <td mat-cell *matCellDef="let element">
                          {{
                            element.roe_expense_amount !== null &&
                            isValidNumber(element.roe_expense_amount)
                              ? (element.roe_expense_amount | number : "1.2-2")
                              : "--"
                          }}
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef class="text-end">
                          Action
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-end">
                          <mat-icon
                            [matMenuTriggerFor]="menuEdit"
                            (click)="setElement(element)"
                            style="cursor: pointer"
                          >
                            more_vert
                          </mat-icon>
                        </td>
                        <mat-menu #menuEdit="matMenu" class="action-info">
                          <button
                            mat-menu-item
                            (click)="openExpenseEditDialog(element)"
                          >
                            Edit
                          </button>
                          <button
                            mat-menu-item
                            (click)="deleteExpense(element?.roe_expenseID)"
                          >
                            Delete
                          </button>
                        </mat-menu>
                      </ng-container>
                      <tr
                        mat-header-row
                        class="account-detail-header"
                        *matHeaderRowDef="displayedColumns3"
                      ></tr>
                      <tr
                        mat-row
                        class="account-detail-body"
                        *matRowDef="let row; columns: displayedColumns3"
                      ></tr>
                    </table>
                    <div
                      *ngIf="
                        expenseForm.get('searchQuery')?.value &&
                        expenseList.length === 0
                      "
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                      "
                    >
                      <p class="required-star" style="font-size: 1.5rem">
                        No re-occurring expenses found.
                      </p>
                    </div>
                    <mat-paginator
                      *ngIf="expenseList.length > 0"
                      class="action-table-pagination w-full"
                      [length]="totalExpenseRecords"
                      [pageSize]="expensePageSize"
                      [pageIndex]="expensePageNumber"
                      [pageSizeOptions]="[25, 50, 75, 100]"
                      (page)="onExpensePageChange($event)"
                      aria-label="Select page"
                    >
                    </mat-paginator>
                  </ng-container>
                </div>
              </div>
              <div
                *ngIf="expenseList.length === 0 && !loading"
                class="cost-info overflow-scroll h-full"
              >
                <div class="text-center mt-12 bgform-img">
                  <img src="/images/progress.png" alt="form-bg" />
                </div>
                <div class="w-md m-auto">
                  <p class="mat-headline-5 text-center mb-4 mt-6">
                    No Expenses Recorded Yet
                  </p>
                  <span class="textS text-center d-block mb-4 grey-text fw-400">
                    Zero expenses are currently recorded for this account. Begin
                    tracking your financial activity by adding your first
                    expense. Let's start monitoring your spending now.
                  </span>
                </div>
                <div class="text-center">
                  <sft-button
                    [label]="'Add Recurring Expense'"
                    [color]="'primary'"
                    [variant]="'flat'"
                    [disable]="false"
                    [icon]="'home'"
                    [disableRipple]="false"
                    [size]="'large'"
                    (buttonClick)="openExpenseDialog()"
                  ></sft-button>
                </div>
              </div>
            </mat-tab>

            <!-- ACTIVITY LOGS -->
            <mat-tab label="Activity">
              <div class="search-block mt-6">
                <mat-form-field
                  appearance="outline"
                  class="search-input"
                  [formGroup]="activityForm"
                >
                  <input
                    type="text"
                    matInput
                    placeholder="Search"
                    (keydown.enter)="$event.preventDefault()"
                    formControlName="searchQuery"
                  />
                  <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
              </div>
              <div class="activity-listing overflow-scroll h-full mt-6">
                <app-loader *ngIf="loading"></app-loader>
                <ng-container
                  *ngIf="activityLogsGrouped.length > 0; else noLogsMessage"
                >
                  <ng-container *ngFor="let group of activityLogsGrouped">
                    <div class="py-2" *ngIf="!loading">
                      <p class="body-bg p-2 fw-400 textXS text">
                        {{ formatDate(group.date) }}
                      </p>
                      <div
                        *ngFor="let log of group.logs"
                        class="activity-update d-flex align-center"
                      >
                        <div class="activity-time">
                          <p class="p-2 text-grey4 fw-400 textXS">
                            {{ log.timestamp | date : "shortTime" : "UTC" }}
                          </p>
                        </div>
                        <div class="activity-info align-center d-flex mr-8">
                          <div class="avatar-smX mr-2">
                            <div
                              style="
                                background-color: #005f8e;
                                color: #fff;
                                border-radius: 50%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 10px;
                              "
                            >
                              {{ getDefaultPhotoText(log.user.name) }}
                            </div>
                          </div>
                          <span class="textS text">{{ log.user.name }}</span>
                        </div>
                        <div class="activity-discription">
                          <p class="p-2 text-grey4 fw-400 textXS">
                            {{ log.activityDetail }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noLogsMessage>
                  <p
                    class="required-star"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                      font-size: 16px;
                    "
                  >
                    No activity logs found.
                  </p>
                </ng-template>
              </div>
              <mat-paginator
                *ngIf="activityLogsGrouped.length > 0"
                class="action-table-pagination expense activity fixed bottom-0"
                [length]="totalActivityRecords"
                [pageSize]="activityPageSize"
                [pageSizeOptions]="[25, 50, 75, 100]"
                (page)="onActivityPageChange($event)"
                aria-label="Select page"
              >
              </mat-paginator>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
<app-resource-side-panel
  #resource
  (resourceAdded)="refreshProjectDataCount()"
></app-resource-side-panel>
<app-generate-invoice-side-panel
  [accountId]="id"
  [billingCycle]="billingCycle"
  [creditTerm]="creditTerm"
  #invoice
></app-generate-invoice-side-panel>
<app-generate-fixed-cost-invoice-side-panel
  [billingCycle]="billingCycle"
  [creditTerm]="creditTerm"
  #fixedInvoice
></app-generate-fixed-cost-invoice-side-panel>
