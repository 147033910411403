import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularMaterialModule } from '../../../../shared/modules/angular-material/angular-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SftButtonModule } from '../../../../stories/components/button/button.module';
import { SftInputModule } from '../../../../stories/components/Input/input.module';
import { SftSwitchButtonModule } from '../../../../stories/components/switch/switch.module';
import { AccountListComponent } from './components/account-list/account-list.component';
import { CommonModule } from '@angular/common';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { LayoutModule } from '../../core/layouts/layout/layout.module';
import { AccountActionDetailComponent } from './components/account-action-detail/account-action-detail.component';
import { AccountRoutingModule } from './account-routing.module';
import { DialogModule } from '../../../../shared/components/dialog/dialog.module';
import { SharedModule } from 'src/shared/modules/shared/shared.module';
import { LoaderComponent } from 'src/shared/components/loader/loader.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { ResourceSidePannelComponent } from './components/resource-side-panel/resource-side-panel.component';
import { ConfirmationDialogComponent } from 'src/shared/components/dialog/admin-dialogs/confirmation-dialog/confirmation-dialog.component';
import { SidebarModule } from 'src/app/admin-portal/core/layouts/sidebar/sidebar.module';
import { LogoCropperComponent } from './components/logo-cropper/logo-cropper.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { GenerateInvoiceSidePannelComponent } from './components/generate-invoice-side-panel/generate-invoice-side-panel.component';
import { InvoiceDetailsComponent } from './components/invoice-details/invoice-details.component';
import { GenerateInvoiceFixedCostSidePannelComponent } from './components/generate-invoice-fixed-cost-side-panel/generate-invoice-fixed-cost-side-panel.component';

@NgModule({
  declarations: [
    CreateAccountComponent,
    AccountListComponent,
    AccountActionDetailComponent,
    ConfirmationDialogComponent,
    ResourceSidePannelComponent,
    LogoCropperComponent,
    GenerateInvoiceSidePannelComponent,
    InvoiceDetailsComponent,
    GenerateInvoiceFixedCostSidePannelComponent,
  ],
  imports: [
    LoaderComponent,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    AngularMaterialModule,
    SidebarModule,
    SftButtonModule,
    SftInputModule,
    SftSwitchButtonModule,
    CommonModule,
    AccountRoutingModule,
    DialogModule,
    SharedModule,
    ImageCropperComponent,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-IN' },
    { provide: LOCALE_ID, useExisting: MAT_DATE_LOCALE },
  ],
})
export class AccountModule {}
