import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountListComponent } from './components/account-list/account-list.component';
import { AccountActionDetailComponent } from './components/account-action-detail/account-action-detail.component';
import { CreateAccountComponent } from './components/create-account/create-account.component';

const routes: Routes = [
  {
    path: '',
    component: AccountListComponent,
  },
  {
    path: 'create',
    component: CreateAccountComponent,
  },
  {
    path: 'edit/:id',
    component: CreateAccountComponent,
  },
  {
    path: 'view/:id',
    component: AccountActionDetailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
