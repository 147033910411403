import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceDateDialogComponent } from 'src/shared/components/dialog/admin-dialogs/invoice-date-dialog/invoice-date-dialog.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { InvoicingService } from '../../../invoicing/services/invoicing.service';
import { ProjectService } from '../../../project/services/project.service';
import { MatSort } from '@angular/material/sort';

export interface Resource {
  modifiedAt: string | number | Date;
  name: string;
  rate: string;
  allocatedTime: string;
  role: string;
  currency: string;
  projectResourceID: string;
  projectID: string;
  invoiceItemID: string;
}

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
})
export class InvoiceDetailsComponent implements OnInit {
  loading: boolean = false;
  resource: Resource[] = [];
  totalRecords: number = 0;
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'resourceName' | 'modifiedAt' = 'modifiedAt';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  currency: string = '';
  lastSortBy: string = '';
  costModal: string = '';
  form!: FormGroup;
  projectId: string = '';
  lastInvoiceDate: string = '';

  displayedColumns2: string[] = ['name', 'rate', 'hours'];
  dataSource = new MatTableDataSource<Resource>([]);

  resourcesFormArray!: FormArray;

  @Output() goBack = new EventEmitter<void>();
  @Input() accountId: string = '';
  @Input() billingCycle: string = '';
  @Input() creditTerm: string = '';
  @Input() modifyTriggered: boolean = false;
  @Input() generateTriggered: boolean = false;
  @Input() invoiceID: string = '';
  @Input() invoiceItemId: string = '';
  @Input() projectResourceCheck: string = '';
  @Input() projectType: string = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public dialog: MatDialog,
    private invoiceService: InvoicingService,
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {
    this.dataSource.sort = this.sort;

    this.form = this.formBuilder.group({
      searchQuery: [''],
      resources: this.formBuilder.array([]),
    });

    this.resourcesFormArray = this.form.get('resources') as FormArray;

    this.searchResources();

    this.globalService.getProjectId().subscribe((projectId) => {
      this.projectId = projectId || '';
    });

    this.globalService.getAccountId().subscribe((accountId) => {
      this.accountId = accountId || '';
    });

    if (this.generateTriggered) {
      this.getProjectResourceList(
        this.pageNumber,
        this.limit,
        this.search,
        this.sortBy,
        this.orderBy,
        this.projectId
      );
    } else {
      this.getInvoiceProjectResourceList(this.invoiceID);
    }

    this.getLastInvoiceGeneratedDate();
    this.dataSource.paginator = this.paginator;
    console.log(this.projectType);
  }

  applySorting(event: any): void {
    const data = this.dataSource.data.slice();
    if (!event.active || event.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onPaginateChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.updateDataSource();
  }

  updateDataSource(): void {
    const startIndex = this.pageNumber * this.limit;
    const endIndex = startIndex + this.limit;
    if (startIndex >= this.resource.length) {
      this.dataSource.data = [];
    } else {
      this.dataSource.data = this.resource.slice(startIndex, endIndex);
    }
  }

  getFormControl(index: number, controlName: string): FormControl {
    const group = this.resourcesFormArray.at(index) as FormGroup;
    return group
      ? (group.get(controlName) as FormControl)
      : new FormControl('');
  }

  createResourceFormGroup(resource: Resource): FormGroup {
    return this.formBuilder.group({
      allocatedTime: [resource.allocatedTime],
      invoiceItemID: [resource.invoiceItemID],
    });
  }

  populateResourcesFormArray(resources: Resource[]): void {
    const resourceFormGroups = resources.map((resource) =>
      this.createResourceFormGroup({
        ...resource,
        allocatedTime: this.generateTriggered ? '0' : resource.allocatedTime,
      })
    );

    this.resourcesFormArray.clear();
    resourceFormGroups.forEach((formGroup) => {
      this.resourcesFormArray.push(formGroup);
    });
  }

  toggleInvoiceDetails(showInvoiceDetails: boolean): void {
    if (showInvoiceDetails) {
      this.goBack.emit();
    }
  }

  getLastInvoiceGeneratedDate(): void {
    const projectType = this.projectType;
    this.invoiceService
      .getLastInvoiceGeneratedDate(projectType)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.lastInvoiceDate = data?.data;
        },
        error: (error: any) => {
          console.error('Error fetching last invoice date:', error);
          this.loading = false;
        },
      });
  }

  openDialogInvoiceDate(): void {
    const actionType = this.modifyTriggered ? 'modify' : 'generate';

    const dialogRef = this.dialog.open(InvoiceDateDialogComponent, {
      height: 'auto',
      width: '467px',
      data: {
        accountID: this.accountId,
        projectId: this.projectId,
        invoiceId: this.invoiceID,
        resources: this.resourcesFormArray
          .getRawValue()
          .map((resource, index) => ({
            rate: this.dataSource.data[index]?.rate,
            invoiceItemID: this.dataSource.data[index]?.invoiceItemID,
            allocatedTime: resource?.allocatedTime,
            projectResourceID: this.dataSource.data[index]?.projectResourceID,
          })),
        billingCycle: this.billingCycle,
        creditTerm: this.creditTerm,
        lastInvoiceDate: this.lastInvoiceDate,
        actionType: actionType,
      },
    });

    dialogRef.componentInstance.updateInvoiceDetails.subscribe(() => {
      this.toggleInvoiceDetails(true);
    });
  }

  searchResources(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getProjectResourceList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy,
          this.projectId
        );
      });
  }

  getInvoiceProjectResourceList(invoiceID: string): void {
    this.loading = true;
    this.invoiceService
      .getInvoiceItemListById(invoiceID)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          const invoiceItems = data.data.invoiceItems || [];
          this.resource = invoiceItems.map((record: any) => ({
            name: record?.projectResource?.resource.name,
            role: record?.projectResource?.resource.role,
            rate: record.rate,
            allocatedTime: record.allocatedTime,
            projectResourceID: record?.projectResource?.projectResourceID,
            invoiceItemID: record?.invoiceItemID,
          }));

          this.totalRecords = this.resource.length;
          this.loading = false;
          this.currency = data.data.account?.financials[0]?.currency || 'USD';

          this.updateDataSource();
          this.populateResourcesFormArray(this.resource);
        },
        error: (error: any) => {
          console.error('Error fetching project resource list:', error);
          this.loading = false;
        },
      });
  }

  getProjectResourceList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    projectID?: string
  ): void {
    this.loading = true;
    this.projectService
      .getProjectResource(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.projectId
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          const resources = data.data.records.map((record: any) => ({
            name: record.resource.name,
            rate: record.rate,
            allocatedTime: record.allocatedTime,
            role: record.resource.role,
            projectResourceID: record.projectResourceID,
          }));

          this.resource = resources;
          this.totalRecords = data?.data?.count || 0;
          this.loading = false;
          this.currency =
            data.data.records[0]?.project?.account?.financials[0]?.currency;

          this.updateDataSource();
          this.populateResourcesFormArray(resources);
        },
        error: (error: any) => {
          console.error('Error fetching project resources:', error);
          this.loading = false;
        },
      });
  }

  getDefaultPhotoText(resourceName: string): string {
    if (!resourceName) return '';

    const firstChar = resourceName.charAt(0);
    const indexOfFirstSpace = resourceName.indexOf(' ');
    let secondChar = '';
    if (
      indexOfFirstSpace !== -1 &&
      indexOfFirstSpace < resourceName.length - 1
    ) {
      secondChar = resourceName.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }
}
