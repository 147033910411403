import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { debounceTime, takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { ConfirmationDialogComponent } from 'src/shared/components/dialog/admin-dialogs/confirmation-dialog/confirmation-dialog.component';
import { ProjectResourceDialogAccountDetailsComponent } from 'src/shared/components/dialog/admin-dialogs/project-resource-dialog-account-detail/project-resource-dialog-account-detail.component';

export interface PeriodicElement {
  name: string;
  rate: string;
  date: string;
}

@Component({
  selector: 'app-resource-side-panel',
  templateUrl: './resource-side-panel.component.html',
})
export class ResourceSidePannelComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private projectService: ProjectService,
    private globalService: GlobalService,
    private formbuilder: FormBuilder
  ) {}

  currency: string = '';
  totalRecords: number = 0;
  projectId: string = '';
  costModal: string = '';
  isOpen: boolean = false;
  loading: boolean = false;
  resource: any[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'resourceName' | 'modifiedAt' = 'modifiedAt';
  lastSortBy: string = '';
  orderBy: 'ASC' | 'DESC' = 'ASC';
  form!: FormGroup;
  showButtons: boolean = false;

  @Input() initialLoad: boolean = true;
  @Output() resourceAdded: EventEmitter<any> = new EventEmitter<any>();

  displayedColumns: string[] = ['name', 'rate', 'date'];
  dataSource = new MatTableDataSource<PeriodicElement>([]);

  ngOnInit(): void {
    this.sortBy = 'resourceName';

    this.form = this.formbuilder.group({
      searchQuery: [''],
    });
    this.searchResources();
    if (this.resource.length === 0) {
      this.loadData();
    }
    // this.getProjectResources(
    //   this.pageNumber,
    //   this.limit,
    //   this.search,
    //   this.sortBy,
    //   this.orderBy,
    //   this.projectId
    // );
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;

    this.getProjectResources(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy,
      this.projectId
    );
  }

  onSort(event: Sort): void {
    let sortBy = event.active;
    const orderBy = event.direction.toUpperCase() as 'ASC' | 'DESC';
    const validOrderBy = orderBy || 'ASC';

    if (sortBy === 'rate') {
      sortBy = this.costModal === 'fixed' ? 'salary' : 'rate';
    }

    if (sortBy !== this.lastSortBy) {
      this.pageNumber = 0;
    }
    this.lastSortBy = sortBy;

    this.projectService
      .getProjectResource(
        this.pageNumber,
        this.limit,
        this.search,
        sortBy,
        validOrderBy,
        this.projectId
      )
      .subscribe({
        next: (data: any) => {
          this.resource = data?.data?.records || [];
          this.dataSource.data = this.resource;
        },
        error: (error: any) => {
          console.error('Error in Resource List request:', error);
        },
      });
  }

  loadData(): void {
    this.globalService.projectRSubject.subscribe((event) => {
      if (event) {
        this.projectId = event;
        this.getProjectResources(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy,
          this.projectId
        );
        this.getProject(this.projectId);
      }
    });
  }

  openResourceDrawer(): void {
    this.isOpen = !this.isOpen;
    if (!this.initialLoad) {
      this.loadData();
    }
  }

  getProjectResources(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC',
    projectId: string
  ): void {
    this.loading = true;
    this.projectService
      .getProjectResource(pageNumber, limit, search, sortBy, orderBy, projectId)
      .subscribe({
        next: (data: any) => {
          this.resource = data.data.records;
          this.totalRecords = data?.data?.count || 0;
          this.currency =
            data.data.records[0]?.project?.account?.financials[0]?.currency;
          this.loading = false;
        },
        error: (error: any) => {
          console.error('Error fetching total resource count:', error);
          this.loading = false;
        },
      });
  }

  getProject(projectId: string): void {
    this.loading = true;
    this.projectService.getProjectById(projectId).subscribe({
      next: (data: any) => {
        this.costModal = data?.data?.costModal;
        this.loading = false;
      },
      error: (error: any) => {
        console.error('Error fetching total resource count:', error);
        this.loading = false;
      },
    });
  }

  openAddDialog(): void {
    const dialogRef = this.dialog.open(
      ProjectResourceDialogAccountDetailsComponent,
      {
        height: 'auto',
        width: '467px',
        data: {
          isEditMode: false,
          element: this.projectId,
          costModal: this.costModal,
        },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        this.getProjectResources(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy,
          this.projectId
        );
      }
      this.resourceAdded.emit();
    });
  }

  getDefaultPhotoText(resourceName: string): string {
    if (!resourceName) return '';

    const firstChar = resourceName.charAt(0);
    const indexOfFirstSpace = resourceName.indexOf(' ');
    let secondChar = '';
    if (
      indexOfFirstSpace !== -1 &&
      indexOfFirstSpace < resourceName.length - 1
    ) {
      secondChar = resourceName.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }

  openEditDialog(element: any): void {
    const dialogRef = this.dialog.open(
      ProjectResourceDialogAccountDetailsComponent,
      {
        height: 'auto',
        width: '467px',
        data: { isEditMode: true, element: element, costModal: this.costModal },
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.success) {
        const index = this.resource.findIndex((p) => p.id === element.id);
        if (index !== -1) {
          this.resource.splice(index, 1);
          this.resource.unshift(result.updatedResource);
        }
        this.getProjectResources(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy,
          this.projectId
        );
      }
    });
  }

  searchResources(): void {
    this.form
      .get('searchQuery')
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe((x) => {
        this.search = x;
        this.getProjectResources(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy,
          this.projectId
        );
      });
  }

  deleteResource(projectResourceID: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: 'auto',
      width: '467px',
      data: {
        message: 'Are you sure you want to remove this resource?',
      },
    });

    dialogRef.componentInstance.canceled.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.componentInstance.confirmed.subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.projectService.deleteProjectResource(projectResourceID).subscribe({
          next: () => {
            this.getProjectResources(
              this.pageNumber,
              this.limit,
              this.search,
              this.sortBy,
              this.orderBy,
              this.projectId
            );
            dialogRef.close();
            this.resourceAdded.emit();
          },
          error: (error: any) => {
            console.error('Error deleting resources:', error);
          },
        });
      }
    });
  }
}
