<div class="modal-wrapper p-4" [formGroup]="invoiceForm">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">Invoice Date</h2>
      <p class="textXS mt-2 text">Select Date of invoice</p>
    </div>
    <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
  </div>
  <app-loader *ngIf="loading"></app-loader>
  <div *ngIf="!loading" class="content-wrapper my-4">
    <div class="d-flex justify-between mb-8 invoice-term-details body-bg p-2">
      <span class="textXS d-block text-black"
        >Billing cycle date:
        {{ billingCycle ? (billingCycle | date : "dd/MM/yyyy") : "--" }}</span
      >
      <span class="textS d-block text-black"
        >Credit Term: {{ creditTerm }} Days</span
      >
    </div>
    <div class="input-wrapper mb-4">
      <p class="mat-body-1 text mb-1 input-label">
        Invoice Date<span class="required-star">*</span>
      </p>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          required
          matInput
          [matDatepicker]="startpicker"
          placeholder="dd/mm/yyyy"
          formControlName="invoiceDate"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="startpicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startpicker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="errorMessage" class="required-star">
    <p class="text-red-500">{{ errorMessage }}</p>
  </div>
  <div class="modal-footer mt-2 d-flex justify-between align-center">
    <span class="textS grey-test"
      >Last invoice generated on:
      {{
        lastInvoiceDate ? (lastInvoiceDate | date : "dd/MM/yyyy") : "--"
      }}</span
    >
    <sft-button
      [label]="'Generate'"
      [color]="'primary'"
      [variant]="'flat'"
      [size]="'large'"
      [disable]="!invoiceForm.valid"
      (buttonClick)="generateInvoice()"
    ></sft-button>
  </div>
</div>
