import { Component, inject, signal } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export type CropperDialogData = {
  image: File;
  width: number;
  height: number;
};

export type CropperDialogResult = {
  blob: Blob;
  imageUrl: string;
};

@Component({
  selector: 'app-cropper-dialog',
  templateUrl: './logo-cropper.component.html',
})
export class LogoCropperComponent {
  data: CropperDialogData = inject(MAT_DIALOG_DATA);

  calculateAspectRatio(): number {
    if (this.data.width && this.data.height) {
      return this.data.width / this.data.height;
    }
    return 1;
  }

  result = signal<CropperDialogResult | undefined>(undefined);

  imageCropped(event: ImageCroppedEvent) {
    const { blob, objectUrl } = event;
    if (blob && objectUrl) {
      this.result.set({ blob, imageUrl: objectUrl });
    }
  }
}
