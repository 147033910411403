import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoicingService } from '../../../invoicing/services/invoicing.service';
import { takeUntil } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceDateFixedDialogComponent } from 'src/shared/components/dialog/admin-dialogs/invoice-date-fixed-dialog/invoice-date-fixed-dialog.component';
import moment from 'moment';

@Component({
  selector: 'app-generate-fixed-cost-invoice-side-panel',
  templateUrl: './generate-invoice-fixed-cost-side-panel.component.html',
})
export class GenerateInvoiceFixedCostSidePannelComponent implements OnInit {
  isOpen: boolean = false;
  showInvoiceDetails = false;
  loading: boolean = false;
  invoicingList: any[] = [];
  invoicingDetail: any[] = [];
  pageNumber: number = 0;
  limit: number = 25;
  search: string = '';
  sortBy: 'modifiedAt' = 'modifiedAt';
  orderBy: 'DESC' | 'ASC' = 'DESC';
  totalRecords: number = 0;
  lastInvoiceDate: string = '';
  modifyTriggered: boolean = false;
  generateTriggered: boolean = false;
  selectedInvoiceItemID: string = '';
  selectedInvoiceID: string = '';
  projectId: string = '';
  fixedCost: string = '';
  selectedYear: string = '2024';
  availableYears: string[] = [];
  type: string = '';

  constructor(
    private invoicingService: InvoicingService,
    private globalService: GlobalService,
    private dialog: MatDialog
  ) {}

  @Input() accountId: string = '';
  @Input() initialLoad: boolean = true;
  @Input() billingCycle: string = '';
  @Input() creditTerm: string = '';
  @Input() element: any;
  @Input() rate: string = '0';

  @Output() modifyClick = new EventEmitter<void>();
  @Output() generateClick = new EventEmitter<void>();

  ngOnInit(): void {
    this.fetchAvailableYears();
    this.globalService.getProjectId().subscribe((projectId) => {
      this.projectId = projectId;
    });

    this.globalService.getFixedCost().subscribe((fixedCost) => {
      this.fixedCost = fixedCost;
    });

    this.globalService.getProjectType().subscribe((type: string) => {
      this.type = type;
      if (this.type) {
        this.getInvoicingList(
          this.pageNumber,
          this.limit,
          this.search,
          this.sortBy,
          this.orderBy
        );
        this.getLastInvoiceGeneratedDate();
      }
    });
  }

  fetchAvailableYears(): void {
    this.invoicingService
      .getInvoiceYear()
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.availableYears =
            data?.data?.records?.map((item: any) => item.year.toString()) || [];
          if (this.availableYears.length > 0) {
            this.selectedYear = this.availableYears[0];
          }
        },
        error: (error: any) => {
          console.error('Error fetching available years:', error);
        },
      });
  }

  onDrawerOpenedChange(isOpen: boolean): void {
    this.isOpen = isOpen;
    if (!isOpen) {
      this.resetFilters();
    }
  }

  onYearChange(selectedYear: string): void {
    this.selectedYear = selectedYear;
    this.getInvoicingList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
  }

  resetFilters(): void {
    this.selectedYear = '2024';
    this.invoicingList = [];
    this.pageNumber = 0;
    this.getInvoicingList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy
    );
  }

  formatInvoiceNumberDate(invoice: any): string {
    const startDate = new Date(this.billingCycle);
    const billingCycleDay = startDate.getDate();
    const createdAt = new Date(invoice.created_at);
    const month = createdAt.toLocaleString('default', { month: 'short' });
    const year = createdAt.getFullYear();

    const endDate = moment(startDate).add(1, 'month').format('DD MMM YYYY');
    return `${billingCycleDay} ${month} ${year} - ${endDate}`;
  }

  generateInvoiceClicked(invoiceID?: string) {
    this.generateTriggered = true;
    this.modifyTriggered = false;
    if (invoiceID) {
      this.selectedInvoiceID = invoiceID;
      this.openDialogInvoiceDate();
    }
  }

  modifyInvoiceClicked(invoiceID?: string) {
    this.modifyTriggered = true;
    this.generateTriggered = false;
    if (invoiceID) {
      this.selectedInvoiceID = invoiceID;
      this.getInvoicingDetail(invoiceID);
    }
  }

  getLastInvoiceGeneratedDate(): void {
    const projectType = this.type;
    console.log('Project type:', this.type);
    if (!projectType) {
      console.error('Project type is not set');
      return;
    }

    this.invoicingService
      .getLastInvoiceGeneratedDate(projectType)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.lastInvoiceDate = data?.data || '';
        },
        error: (error: any) => {
          console.error('Error fetching last invoice date:', error);
          this.loading = false;
        },
      });
  }

  openDialogInvoiceDate(): void {
    const actionType = this.modifyTriggered ? 'modify' : 'generate';

    const dialogRef = this.dialog.open(InvoiceDateFixedDialogComponent, {
      height: 'auto',
      width: '467px',
      data: {
        accountID: this.accountId,
        projectId: this.projectId,
        billingCycle: this.billingCycle,
        creditTerm: this.creditTerm,
        lastInvoiceDate: this.lastInvoiceDate,
        fixedCost: this.fixedCost,
        invoiceID: this.selectedInvoiceID,
        invoiceItemID: this.selectedInvoiceItemID,
        actionType: actionType,
        projectType: this.type,
      },
    });

    dialogRef.componentInstance.updateInvoiceDetails.subscribe(() => {});
  }

  getInvoicingList(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: 'ASC' | 'DESC'
  ): void {
    this.loading = true;
    this.invoicingService
      .getInvoiceList(
        pageNumber,
        limit,
        search,
        sortBy,
        orderBy,
        this.selectedYear,
        '',
        this.projectId,
        '',
        '',
        this.type
      )
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingList = data?.data?.records || [];
          this.totalRecords = data?.data?.count || 0;

          this.loading = false;
          this.invoicingList.sort((a, b) => {
            return (
              new Date(b.modifiedAt).getTime() -
              new Date(a.modifiedAt).getTime()
            );
          });
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing list:', error);
          this.loading = false;
        },
      });
  }

  getInvoicingDetail(invoiceId: string): void {
    this.loading = true;
    this.invoicingService
      .getInvoiceItemListById(invoiceId)
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe({
        next: (data: any) => {
          this.invoicingDetail = data?.data || [];
          this.selectedInvoiceItemID =
            data?.data?.invoiceItems[0]?.invoiceItemID || '';
          this.loading = false;
          this.openDialogInvoiceDate();
        },
        error: (error: any) => {
          console.error('Error in fetching invoicing Detail list:', error);
          this.loading = false;
        },
      });
  }

  getFormattedStatus(status: string): string {
    if (status === 'draft') {
      return 'Draft';
    } else if (status === 'sent') {
      return 'Sent';
    } else if (status === 'void') {
      return 'Void';
    } else if (status === 'received') {
      return 'Received';
    } else {
      return status;
    }
  }

  onPageChange(event: PageEvent): void {
    this.pageNumber = event.pageIndex;
    this.limit = event.pageSize;
    this.getInvoicingList(
      this.pageNumber,
      this.limit,
      this.search,
      this.sortBy,
      this.orderBy || 'ASC'
    );
  }
}
