<sft-button
  [label]="'Resources assign to this project'"
  [color]="'primary'"
  className="grey-text-btn textXS p-0 mb-6"
  [variant]="'default'"
  [disable]="false"
  [withIcon]="true"
  [icon]="'keyboard_backspace'"
  [disableRipple]="false"
  [size]="'small'"
  (buttonClick)="goBack.emit()"
></sft-button>

<div class="pb-6 pt-6">
  <div class="d-flex justify-between align-center account-detail-actions">
    <div class="search-block">
      <mat-form-field
        appearance="outline"
        class="search-input"
        [formGroup]="form"
      >
        <input
          type="text"
          matInput
          placeholder="Search"
          formControlName="searchQuery"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="d-flex align-center">
      <sft-button
        [label]="'Generate Invoice'"
        [color]="'primary'"
        [variant]="'flat'"
        [disable]="false"
        [icon]="'home'"
        [disableRipple]="false"
        [size]="'medium'"
        (click)="openDialogInvoiceDate()"
      ></sft-button>
    </div>
  </div>
</div>

<app-loader *ngIf="loading"></app-loader>

<ng-container *ngIf="!loading">
  <div
    class="account-detail-table detail-listing listing-border table-border resource-pagination w-full overflow-auto overflow-y-hidden"
  >
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      [formGroup]="form"
      (matSortChange)="applySorting($event)"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Resource Name</th>
        <td mat-cell *matCellDef="let element">
          <div class="d-flex align-center">
            <div class="avatar-sm mr-2">
              <ng-container *ngIf="element.logo; else defaultLogo">
                <img
                  src="/images/profile.png"
                  alt="profile"
                  style="border-radius: 50%"
                />
              </ng-container>
              <ng-template #defaultLogo>
                <div
                  class="table-avatar mr-2"
                  style="
                    background-color: #8f8fc3;
                    color: #fff;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  {{ getDefaultPhotoText(element?.name) }}
                </div>
              </ng-template>
            </div>
            <div>
              <span class="d-block primary-light-text textS">{{
                element.name
              }}</span>
              <h3 class="textXS text">{{ element.role }}</h3>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="rate" class="rate-col">
        <th mat-header-cell *matHeaderCellDef class="project-info p-3">
          Per Hour Rate ({{ currency }})
        </th>
        <td mat-cell *matCellDef="let element">{{ element.rate }}</td>
      </ng-container>

      <ng-container matColumnDef="hours">
        <th mat-header-cell *matHeaderCellDef class="project-info p-3">
          Total Hours
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-form-field
            appearance="outline"
            class="w-full hours-col-field"
            subscriptSizing="dynamic"
          >
            <input
              matInput
              [formControl]="getFormControl(i, 'allocatedTime')"
              placeholder="hh:mm"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <tr
        mat-header-row
        class="account-detail-header"
        *matHeaderRowDef="displayedColumns2"
      ></tr>
      <tr
        mat-row
        class="account-detail-body"
        *matRowDef="let row; columns: displayedColumns2"
      ></tr>
    </table>
    <mat-paginator
      class="action-table-pagination w-unset"
      [length]="totalRecords"
      [pageSize]="limit"
      [pageSizeOptions]="[25, 50, 75, 100]"
      (page)="onPaginateChange($event)"
    >
    </mat-paginator>
  </div>
</ng-container>
