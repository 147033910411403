<div class="modal-wrapper p-6">
  <div class="modal-header d-flex justify-between">
    <div>
      <h2 class="mat-subtitle-1 mb-0 text">Confirmation</h2>
    </div>
    <mat-icon class="pointer" (click)="onNoClick()">close</mat-icon>
  </div>
  <div class="content-wrapper mt-2 mb-6">
    <p class="text textS">{{ data.message }}</p>
    <div class="col mt-6 s12 m6 l6">
      <div class="input-wrapper">
        <p class="mat-body-1 text mb-1 input-label">
          Off-Board Date <span class="required-star">*</span>
        </p>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="dd/mm/yyyy"
            [formControl]="offBoardDate"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-end">
    <sft-button
      [label]="'Cancel'"
      (click)="onNoClick()"
      className="mr-2"
      [color]="'primary'"
      [variant]="'stroked'"
      [disable]="false"
      [icon]="'home'"
      [disableRipple]="false"
      [size]="'large'"
    ></sft-button>
    <sft-button
      [label]="'Save'"
      (click)="onYesClick()"
      [color]="'primary'"
      [variant]="'flat'"
      [disableRipple]="false"
      [size]="'large'"
    ></sft-button>
  </div>
</div>
