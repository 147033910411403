<mat-drawer-container
  class="pannel-container resource sidebar resource-modal-wrapper unset h-unset overflow-unset"
  [hasBackdrop]="true"
>
  <mat-drawer
    #drawer
    position="end"
    [opened]="isOpen"
    (openedChange)="isOpen = $event"
  >
    <div class="modal-header d-flex justify-between resource mb-6">
      <div>
        <h2 class="mat-subtitle-1 mb-0 grey-text">Resource List</h2>
        <p class="textS text">Resources assign to this project</p>
      </div>
      <mat-icon class="pointer" (click)="drawer.toggle()">close</mat-icon>
    </div>

    <app-loader *ngIf="loading"></app-loader>
    <ng-container *ngIf="!loading">
      <div
        class="content-wrapper resource-info resource-listing"
        *ngIf="resource.length > 0 || form.get('searchQuery')?.value"
      >
        <div class="pb-6 pt-6">
          <div
            class="d-flex justify-between align-center account-detail-actions"
          >
            <div class="search-block">
              <mat-form-field
                appearance="outline"
                class="search-input"
                [formGroup]="form"
              >
                <input
                  type="text"
                  matInput
                  placeholder="Search"
                  formControlName="searchQuery"
                />
                <mat-icon matSuffix>search</mat-icon>
              </mat-form-field>
            </div>
            <div class="d-flex align-center">
              <sft-button
                [label]="'Add Resource'"
                [color]="'primary'"
                [variant]="'flat'"
                [disable]="false"
                [icon]="'home'"
                [disableRipple]="false"
                [size]="'medium'"
                (click)="openAddDialog()"
              ></sft-button>
            </div>
          </div>
        </div>
        <div
          class="account-detail-table resource-pagination w-full overflow-auto overflow-y-hidden"
        >
          <div
            class="required-star"
            *ngIf="
              !loading &&
              form.get('searchQuery')?.value &&
              resource.length === 0
            "
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              font-size: 20px;
            "
          >
            No project resources found.
          </div>

          <table
            *ngIf="resource.length > 0"
            mat-table
            [dataSource]="resource"
            matSort
            (matSortChange)="onSort($event)"
          >
            <ng-container matColumnDef="name">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header="resourceName"
              >
                Resource Name
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex align-center">
                  <div class="avatar-sm mr-2">
                    <ng-container *ngIf="element.logo; else defaultLogo">
                      <img
                        src="/images/profile.png"
                        alt="profile"
                        style="border-radius: 50%"
                      />
                    </ng-container>
                    <ng-template #defaultLogo>
                      <div
                        class="table-avatar mr-2"
                        style="
                          background-color: #8f8fc3;
                          color: #fff;
                          border-radius: 50%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        {{ getDefaultPhotoText(element.resource?.name) }}
                      </div>
                    </ng-template>
                  </div>
                  <div>
                    <span class="d-block" style="color: #30a4dc">{{
                      element.resource?.name
                    }}</span>
                    <h3 class="textXS text">{{ element.resource?.role }}</h3>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="rate">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="project-info"
              >
                {{
                  costModal === "fixed"
                    ? "Resource Salary (" + currency + ")"
                    : costModal === "hourly"
                    ? "Per Hour Rate (" + currency + ")"
                    : costModal === "daily"
                    ? "Per Day Rate (" + currency + ")"
                    : ""
                }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{
                  costModal === "fixed"
                    ? element?.resource?.salary
                    : element?.rate
                }}
              </td>
            </ng-container>

            <ng-container matColumnDef="date">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="project-info date-wrapper"
              >
                Start date
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                (mouseenter)="showButtons = true"
                (mouseleave)="showButtons = false"
              >
                <ng-container *ngIf="!showButtons">
                  {{ element.startDate | date : "d MMM y" }}
                </ng-container>
                <div *ngIf="showButtons" (click)="$event.stopPropagation()">
                  <sft-button
                    [label]="'Remove'"
                    class="mr-1"
                    [color]="'warn'"
                    [variant]="'default'"
                    [disable]="false"
                    [icon]="'home'"
                    [disableRipple]="false"
                    [size]="'medium'"
                    (buttonClick)="deleteResource(element?.projectResourceID)"
                  ></sft-button>
                  <sft-button
                    [label]="'Edit'"
                    [color]="'primary'"
                    [variant]="'default'"
                    [disable]="false"
                    [icon]="'home'"
                    [disableRipple]="false"
                    [size]="'medium'"
                    (buttonClick)="openEditDialog(element)"
                  ></sft-button>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="action">Action</th>
              <td
                mat-cell
                *matCellDef="let element; let i = index"
                class="text-center"
              >
                <mat-icon
                  [matMenuTriggerFor]="menuEdit"
                  style="cursor: pointer"
                >
                  more_vert
                </mat-icon>
                <mat-menu #menuEdit="matMenu" class="action-info">
                  <button mat-menu-item (click)="openEditDialog(element)">
                    Edit
                  </button>
                  <button
                    mat-menu-item
                    (click)="deleteResource(element?.projectResourceID)"
                  >
                    Delete
                  </button>
                </mat-menu>
              </td>
            </ng-container>
            <tr
              mat-header-row
              class="account-detail-header"
              *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
              mat-row
              class="account-detail-body"
              *matRowDef="let row; columns: displayedColumns"
            ></tr>
          </table>
          <mat-paginator
            class="action-table-pagination w-unset"
            [length]="totalRecords"
            [pageSize]="limit"
            [pageIndex]="pageNumber"
            [pageSizeOptions]="[25, 50, 75, 100]"
            (page)="onPageChange($event)"
            aria-label="Select page"
          >
          </mat-paginator>
        </div>
      </div>
    </ng-container>
    <div class="overlay" *ngIf="isOpen" (click)="openResourceDrawer()"></div>
    <div
      *ngIf="resource.length === 0"
      class="content-wrapper resource-info d-flex justify-center align-center"
    >
      <div class="text-center">
        <div class="mb-6">
          <img src="/images/community.png" alt="intial-img" />
        </div>
        <div class="mb-6">
          <p class="mat-subtitle-1 mb-4 text fw-600">
            No Resources Assigned Yet
          </p>
          <span class="grey-text textS"
            >Looks like there are no resources assigned to this project yet.
            Let's start by adding resources to help execute tasks and achieve
            project goals effectively</span
          >
        </div>
        <div>
          <sft-button
            [label]="'Add Resource'"
            [color]="'primary'"
            [variant]="'flat'"
            [disable]="false"
            [icon]="'home'"
            [disableRipple]="false"
            [size]="'medium'"
            (buttonClick)="openAddDialog()"
          ></sft-button>
        </div>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
