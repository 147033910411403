<div class="p-6 customer-wrapper">
  <form [formGroup]="form">
    <div class="d-flex justify-between align-center">
      <div>
        <h4 class="mat-headline-5 strong" style="margin-bottom: 0">Account</h4>
        <p class="medium mat-body textXS text" style="margin-top: 0">
          Manage Your Customers
        </p>
      </div>
      <ng-container
        *ngIf="accountList.length > 0 || form.get('searchQuery')?.value"
      >
        <div class="d-flex align-center">
          <mat-form-field appearance="outline" class="search-input">
            <input
              type="text"
              matInput
              placeholder="Search"
              formControlName="searchQuery"
              (keydown.enter)="$event.preventDefault()"
            />
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
          <sft-button
            [label]="'New Account'"
            [color]="'primary'"
            [variant]="'flat'"
            [disable]="false"
            [icon]="'home'"
            [disableRipple]="false"
            [size]="'xl'"
            (buttonClick)="createAccount()"
          ></sft-button>
          <div class="tooltip">
            <div class="tooltiptext p-4" *ngIf="showTooltip">
              <div class="modal-header d-flex justify-end">
                <mat-icon class="pointer" (click)="closeTooltip()"
                  >close</mat-icon
                >
              </div>
              <div class="mb-2">
                <p class="mat-subtitle-2 text-left fw-500">Next Step</p>
                <p class="mat-body-1 text-left">
                  Begin adding projects, resources, and your core team by
                  clicking on the action button for seamless setup
                </p>
              </div>
              <div class="d-flex justify-end">
                <button
                  class="tooltip-button primary-light-text pointer"
                  (click)="closeTooltip()"
                >
                  Got It
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <app-loader *ngIf="loading"></app-loader>
    <div
      class="required-star"
      *ngIf="
        !loading && form.get('searchQuery')?.value && accountList.length === 0
      "
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 200px);
        font-size: 20px;
      "
    >
      No accounts found!
    </div>
    <div
      class="body-bg table-container cost-listing-wrapper overflow-scroll"
      *ngIf="!loading && accountList.length > 0"
    >
      <table
        mat-table
        [dataSource]="accountList"
        matSort
        class="custom-action-table mt-0"
        style="cursor: pointer"
        (matSortChange)="onSort($event)"
      >
        <ng-container matColumnDef="accountName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="customer-name"
          >
            Account Name
          </th>

          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/account/view/' + element.accountID"
            title="{{ element.name }}"
          >
            <div class="d-flex align-center">
              <div class="table-avatar mr-2" style="position: relative">
                <ng-container *ngIf="element.logo; else defaultLogo">
                  <img
                    [src]="getLogoUrl(element.accountID)"
                    alt="profile"
                    style="border-radius: 50%"
                  />
                </ng-container>
                <ng-template #defaultLogo>
                  <div
                    class="table-avatar mr-2"
                    style="
                      background-color: #005F8E;
                      color: #fff;
                      border-radius: 50%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    {{ getDefaultLogoText(element.name) }}
                  </div>
                </ng-template>
              </div>
              <span class="customer-name-col">{{
                element.name  || "--"
              }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="softobizAccountManager">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="accountManager"
            class="account-manager"
          >
            Account Manager
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/account/view/' + element.accountID"
            title="{{ element.contracts[0]?.accountManager ?? '--' }}"
            class="table-ellipse account-manager-col customer-name-col"
          >
            {{ element.contracts[0]?.accountManager || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="contractStartDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="startDate"
            class="date"
          >
            Contract Start
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/account/view/' + element.accountID"
          >
            {{
              element.contracts[0]?.startDate
                ? (element.contracts[0]?.startDate | date : "d MMM y")
                : "--"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="clientSPOC">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="clientSpoc"
            class="client"
          >
            Client SPOC
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/account/view/' + element.accountID"
            title="{{ element.contracts[0]?.clientSpoc || '--' }}"
            class="client-info-col table-ellipse account-manager-col customer-name-col"
          >
            {{ element.contracts[0]?.clientSpoc  || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="currency">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="currency-info"
          >
            Currency
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/account/view/' + element.accountID"
          >
            {{ element.financials[0]?.currency || "--" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastupdated">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="modifiedAt"
            sortActionDescription="Sort by date"
            class="update-col"
          >
            Last Updated
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [routerLink]="'/account/view/' + element.accountID"
          >
            {{
              element.modifiedAt
                ? (element.modifiedAt | date : "d MMM y h:mm a" : "UTC")
                : "--"
            }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="action-col text-center">
            Action
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <mat-icon [matMenuTriggerFor]="menu" (click)="setElement(element)"
              >more_vert</mat-icon
            >
            <mat-menu #menu="matMenu">
              <button
                *ngIf="element?.accountID"
                mat-menu-item
                (click)="accountSettings(element?.accountID)"
              >
                Account Settings
              </button>
            </mat-menu>
          </td>
        </ng-container>
        <tr
          mat-header-row
          class="action-table-header-data"
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          class="action-table-body-data"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator
        class="action-table-pagination fixed bottom-0"
        [length]="totalRecords"
        [pageSize]="limit"
        [pageIndex]="pageNumber"
        [pageSizeOptions]="[25, 50, 75, 100]"
        (page)="onPageChange($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </form>
</div>

<div
  class="main-content body-bg p-6 setting-wrapper empty-account-wrapper"
  *ngIf="accountList.length === 0 && !loading"
>
  <div class="d-flex justify-center">
    <div class="account-detail-tab empty settings account-setting w-full p-12">
      <div class="text-center mb-6 bgform-img">
        <img src="/images/bgform.png" alt="form-bg" />
      </div>
      <div>
        <p class="mat-headline-5 text-center mb-4 text">
          Start Adding Accounts
        </p>

        <span class="textS text-center mb-4 d-block grey-text">
          Your account list is empty. Begin by adding your accounts to get
          started <br />
          with managing your finances effectively.
        </span>
        <div class="text-center">
          <sft-button
            [label]="'Add New Account'"
            [color]="'primary'"
            [variant]="'flat'"
            [disable]="false"
            [icon]="'home'"
            [disableRipple]="false"
            [size]="'large'"
            (buttonClick)="newAccountWhenEmptyData()"
          ></sft-button>
        </div>
      </div>
    </div>
  </div>
</div>
